import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from "react-bootstrap";
import bp from "../../media/bp.png";
import './brands.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

const Brands = () => {

    const [brands, setBrands] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const { data } = await axios.get('https://node.instasales.in/api/v1/brand/brands');
                setBrands(data.brands);
            } catch (err) {
                setError('Failed to fetch brands.');
            } finally {
                setLoading(false);
            }
        };
        fetchBrands();
    }, []);

    console.log("brands", brands);

    if (error) {
        return <div>{error}</div>;
    }

    const chunkArray = (array, size) => {
        const chunkedArray = [];
        for (let i = 0; i < array.length; i += size) {
            chunkedArray.push(array.slice(i, i + size));
        }
        return chunkedArray;
    };

    const brandChunks = chunkArray(brands, 4);

    const handleCategoryClick = (category) => {
        window.location.href = (`/categories/${category}`);
    };

    return (
        <div className="brands">
            <LoadingSpinner loading={loading} />
            {!loading && (
                <>
                    <Row>
                        <Col className="brands-h" lg={11}>Explore a World of Brand Diversity</Col>
                        <Col className="brands-color" lg={1}></Col>
                    </Row>
                    <div className="brands-category">
                        <Row className="brands-row">
                            <Col lg={3} className="brands-col">
                                <Button className="brands-btn bbtn-1" onClick={() => handleCategoryClick('Skin Care')}>Skin Care</Button>
                            </Col>
                            <Col lg={3} className="brands-col">
                                <Button className="brands-btn bbtn-2" onClick={() => handleCategoryClick('Art')}>Art</Button>
                            </Col>
                            <Col lg={3} className="brands-col">
                                <Button className="brands-btn bbtn-3" onClick={() => handleCategoryClick('Clothing')}>Clothing</Button>
                            </Col>
                            <Col lg={3} className="brands-col">
                                <Button className="brands-btn bbtn-4" onClick={() => handleCategoryClick('Accessories')}>Accessories</Button>
                            </Col>
                        </Row>
                        <br />
                        <Row className="brands-row">
                            <Col lg={3} className="brands-col">
                                <Button className="brands-btn bbtn-5" onClick={() => handleCategoryClick('Home & Living')}>Home & Living</Button>
                            </Col>
                            <Col lg={3} className="brands-col">
                                <Button className="brands-btn bbtn-6" onClick={() => handleCategoryClick('Food & Beverages')}>Food & Beverages</Button>
                            </Col>
                            <Col lg={3} className="brands-col">
                                <Button className="brands-btn bbtn-7" onClick={() => handleCategoryClick('Kitchen')}>Kitchen</Button>
                            </Col>
                            <Col lg={3} className="brands-col">
                                <Button className="brands-btn bbtn-8" onClick={() => handleCategoryClick('Stationary')}>Stationary</Button>
                            </Col>
                        </Row>
                    </div>
                    <div className="brands-all">
                        <div className="brands-h">All Brands</div>
                        {brandChunks.map((chunk, chunkIndex) => (
                            <Row className="brands-all-row" key={chunkIndex}>
                                {chunk.map((brand, brandIndex) => (
                                    <Col className="brands-all-col" key={brandIndex}>
                                        <Link to={`/brand/${brand.slug}`}>
                                            <Card className="text-white brands-all-card" style={{ height: "100%" }}>
                                                <Card.Img src={brand.brandLogo.url || bp} alt="Card image" className="brands-all-img" />
                                                <Card.ImgOverlay className="brands-all-content">
                                                    <Card.Title className='brands-all-content-name'>{brand.brandName}</Card.Title>
                                                </Card.ImgOverlay>
                                                <Card.ImgOverlay className="brands-all-content-gradient">
                                                </Card.ImgOverlay>
                                            </Card>
                                        </Link>
                                    </Col>
                                ))}
                            </Row>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

const LoadingSpinner = ({ loading }) => {
    if (!loading) return null;

    return (
        <div className="wishlist-loading">
            <ClipLoader size={50} color={"#123abc"} loading={loading} />
        </div>
    );
};

export default Brands;
