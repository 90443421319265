import React, { useEffect, useState } from 'react'
import './wishlist.css'
import { Row, Col, Card, Button, Container } from 'react-bootstrap'
import lc from "../../media/lc.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'
import { ClipLoader } from 'react-spinners';

const Wishlist = () => {
  const [wishlist, setWishlist] = useState([]);
  const [empty, setEmpty] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');

    const fetchWishlist = async () => {
      try {
        const response = await axios.get('https://node.instasales.in/api/v1/wishlist', {
                      headers: {
              'Authorization': `Bearer ${token}`
            }
        });
        const { wishlist } = response.data;
        setWishlist(wishlist.products);
        setEmpty(wishlist.products.length === 0);
      } catch (error) {
        console.error('Error fetching wishlist:', error);
      } finally {
        setLoading(false); // Set loading to false once data fetching is complete
      }
    };

    fetchWishlist();
  }, []);

  const handleAddToWishlist = async (productId) => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.put('https://node.instasales.in/api/v1/wishlist', { productId }, {
                    headers: {
              'Authorization': `Bearer ${token}`
            }
      });
      setWishlist(response.data.wishlist.products);
      setEmpty(response.data.wishlist.products.length === 0);
    } catch (error) {
      console.error('Error updating wishlist:', error);
    }
  };

  const handleAddToCart = async (productId, quantity) => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post('https://node.instasales.in/api/v1/cart', { productId, quantity }, {
                    headers: {
              'Authorization': `Bearer ${token}`
            }
      });
      console.log(response.data.message);
    } catch (error) {
      console.error('Error adding to cart:', error);
    }
  };

  if (loading) {
    return (
      <div className="wishlist-loading">
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  }

  return (
    <div className="wishlist">
      {empty ?
        <div className="wishlist-empty">
          <p className="wishlist-h">Wishlist</p>
          <Container className="wishlist-empty-container">
            <img src={lc} className="wishlist-empty-img" alt="Empty Wishlist" />
            <div className="wishlist-empty-h">You Don’t Have Anything in Your Wishlist</div>
            <div className="wishlist-empty-p">Explore and add items</div>
            <Button className="wishlist-empty-btn">Explore</Button>
          </Container>
        </div>
        :
        <>
          <p className="wishlist-h">Wishlist <span className="bold">({wishlist.length} Items)</span></p>
          <Row className="wishlist-items">
            {wishlist.map((wish) => (
              <Card className="wishlist-item" key={wish._id}>
                <Row className="wishlist-item-details">
                  <Col lg={3} className="wishlist-item-img-container">
                    <img src={wish?.images[0]?.url} className="wishlist-item-img" alt="Wishlist Item" />
                  </Col>
                  <Col lg={6} className="wishlist-item-text">
                    <div className="wishlist-item-text-h">{wish.name}</div>
                    <div className="wishlist-item-text-promo">APPLIED PROMOCODE : 50% off on the {wish.category} product</div>
                    <Button className="wishlist-item-btn-promo">Change promocode</Button>
                    <p className="wishlist-item-price">₹{wish.price}</p>
                  </Col>
                  <Col lg={3} className="wishlist-btn-group">
                    <Button className="wishlist-btn-buy"><FontAwesomeIcon icon={faCheck} className="wishlist-btn-icon" /> Buy Now</Button>
                    <Button className="wishlist-btn-cart" onClick={() => handleAddToCart(wish._id, 1)}><FontAwesomeIcon icon={faPlus} className="wishlist-btn-icon" /> Add to Cart</Button>
                  </Col>
                </Row>
              </Card>
            ))}
          </Row>
        </>
      }
    </div>
  )
}

export default Wishlist;
