import React from 'react'

const Dashboard = React.lazy(() => import('./components/Admin/views/dashboard/Dashboard'))

//Forms
// const ChecksRadios = React.lazy(() => import('./components/Admin/views/forms/checks-radios/ChecksRadios'))
const Helpsuppport = React.lazy(() => import('./components/Admin/views/forms/HelpandSupport'))
const BrandColaboration = React.lazy(() => import('./components/Admin/views/forms/Brandcolaboration'))
const CreatorPartnership = React.lazy(() => import('./components/Admin/views/forms/CreatorPatnership'))

// carts and wishlist
const UsersCart = React.lazy(() => import('./components/Admin/views/CartsAndWishlist/AdminInterface'))
const UserWishlist = React.lazy(() => import('./components/Admin/views/CartsAndWishlist/UserWishlist'))
const ProductLists = React.lazy(() => import('./components/Admin/views/Products/ProductLists'))
const UpdateProduct = React.lazy(() => import('./components/Admin/views/Products/UpdateProduct'))
const NewProduct = React.lazy(() => import('./components/Admin/views/Products/NewProduct'))
const OrderList = React.lazy(() => import('./components/Admin/views/Orders/OrdersLIst'))
const ProcessOrder = React.lazy(() => import('./components/Admin/views/Orders/ProcessOrder'))
const CreatorCategoryPage = React.lazy(() => import('./components/Admin/views/creatorcategorypage/CreatorCategorypage'))




const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },

  
  // { path: '/forms/validation', name: 'Validation', element: Validation },
  { path: '/help-and-support', name: 'help', element: Helpsuppport },
  { path: '/brand-colaboration', name: 'brand-colaboration', element: BrandColaboration },
  { path: '/creator-partnership', name: 'creator-partnership', element: CreatorPartnership },
  { path: '/wishlists', name: 'wishlist', element: UserWishlist },
  { path: '/carts', name: 'cart', element: UsersCart },
  { path: '/products', name: 'products', element: ProductLists },
  { path: '/product/:id', name: 'productDetail', element: UpdateProduct },
  { path: '/product', name: 'NewProduct', element: NewProduct },
  { path: '/orders', name: 'OrderList', element: OrderList },
  { path: '/order/:id', name: 'EditOrder', element: ProcessOrder },
  { path: '/creator-category/:category', name: 'CreatorCategoryPage', element: CreatorCategoryPage },
  


]

export default routes
