import React, { useContext, useEffect, useState } from 'react';
import './cart.css';
import { Row, Col, Card, Button, Form, InputGroup, Container } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPenToSquare, faRotate } from '@fortawesome/free-solid-svg-icons';
import sc from "../../media/sc.png";
import axios from 'axios';
import { bottom } from '@popperjs/core';
import { useProductContext } from '../ProductContext';
import { useParams, useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext';
import OrderProcessingModal from '../Product Summary/OriginalProcessmodel';
import swal from 'sweetalert';


const Cart = () => {
  const [cart, setCart] = useState(null);
  const [empty, setEmpty] = useState(true);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [addresses, setAddresses] = useState([]);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showChangeForm, setShowChangeForm] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedVariation, setSelectedVariation] = useState(null); // State for selected variation
  const [products, setProducts] = useState(null);
  const [showLoader, setShowLoader] = useState(false); // State to control the loader modal
  const [promoCode, setPromocode] = useState('');
  const [promoApplied, setPromoApplied] = useState(null);
  const [applicableBrand, setApplicableBrand] = useState(null);

  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const { selectedProducts, addProduct, removeProduct, clearProducts } = useProductContext();



  useEffect(() => {
    const token = localStorage.getItem('token');

    const fetchCart = async () => {
      try {
        const response = await axios.get('https://node.instasales.in/api/v1/cart', {
                      headers: {
              'Authorization': `Bearer ${token}`
            }
        });
        const { cart } = response.data;
        setCart(cart);
        setEmpty(cart.products.length === 0);
        setProducts(cart.products)
        addProduct({ products })

      } catch (error) {
        console.error('Error fetching cart:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCart();
  }, []);
  const [totalPrice, setTotalPrice] = useState(0);

  const calculateTotalPrice = () => {
    return products?.reduce((total, item) => {
      return total + (item.variant.discountedPrice || item.variant.selling_price) * item.quantity;
    }, 0);
  };

  useEffect(() => {
    setTotalPrice(calculateTotalPrice())


  }, [products]);
  console.log("cart is", cart)
  console.log("selectedProducts is", selectedProducts)
  console.log("products is", products)

  // const totalPrice = calculateTotalPrice();

  useEffect(() => {
    fetchAddresses();
  }, []);

  const fetchAddresses = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get('https://node.instasales.in/api/v1/address', {
                    headers: {
              'Authorization': `Bearer ${token}`
            },
      });
      setAddresses(response.data.addresses);
      setSelectedAddress(response.data.addresses[0]?._id || null);
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };

  const [addressForm, setAddressForm] = useState({
    name: selectedAddress?.name,
    houseNo: selectedAddress?.houseNo,
    street: selectedAddress?.street,
    landmark: selectedAddress?.landmark,
    city: selectedAddress?.city,
    state: selectedAddress?.state,
    postalCode: selectedAddress?.postalCode
  });


  const handleRemoveFromCart = async (productId) => {
    const token = localStorage.getItem('token');

    try {
      await axios.delete(`https://node.instasales.in/api/v1/cart/${productId}`, {
                    headers: {
              'Authorization': `Bearer ${token}`
            }
      });
      const updatedCart = await axios.get('https://node.instasales.in/api/v1/cart', {
                    headers: {
              'Authorization': `Bearer ${token}`
            }
      });
      setCart(updatedCart.data.cart);
      setEmpty(updatedCart.data.cart.products.length === 0);
    } catch (error) {
      console.error('Error removing from cart:', error);
    }
  };

  const fetchUpdatedCart = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get('https://node.instasales.in/api/v1/cart', {
                    headers: {
              'Authorization': `Bearer ${token}`
            }
      });
      return response.data.cart;
    } catch (error) {
      console.error('Error fetching updated cart:', error);
      return null;
    }
  };

  const handleQuantityChange = async (productId, newQuantity) => {
    const token = localStorage.getItem('token');

    try {
      await axios.post('https://node.instasales.in/api/v1/cart', { productId, quantity: newQuantity }, {
                    headers: {
              'Authorization': `Bearer ${token}`
            }
      });
      const updatedCart = await fetchUpdatedCart();
      setCart(updatedCart);
      setEmpty(updatedCart.products.length === 0);
    } catch (error) {
      console.error('Error updating cart quantity:', error);
    }
  };

  const handleIncrement = (productId, currentQuantity) => {
    const newQuantity = currentQuantity + 1;
    handleQuantityChange(productId, newQuantity);
  };

  const handleDecrement = (productId, currentQuantity) => {
    if (currentQuantity > 1) {
      const newQuantity = currentQuantity - 1;
      handleQuantityChange(productId, newQuantity);
    }
  };

  const handleEditAddressClick = () => {
    setShowEditForm(true);
    setShowChangeForm(false);
  };
  const handleEditAddress = (address) => {
    console.log("address in edit", address)
    setEditAddressId(address);
    const addresseditto = addresses.find(addr => addr._id === address)
    setFormData(addresseditto);

    setShowEditForm(true);
    setShowChangeForm(false);
  };

  const handleChangeAddressClick = () => {
    setShowChangeForm(true);
    setShowEditForm(false);
  };

  const handleAddressFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const { name, value } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });

    // If the field is postalCode and the length is 6, fetch the address details
    if (name === 'postalCode' && value.length === 6) {
      fetchAddressDetails(value);
    }

  };

  const handleAddressFormSubmit = async (e) => {
    const token = localStorage.getItem('token');

    e.preventDefault();
    try {
      await axios.post('https://node.instasales.in/api/v1/address', addressForm, {
                    headers: {
              'Authorization': `Bearer ${token}`
            }
      });
      fetchAddresses();
      setShowEditForm(false);
      setShowChangeForm(false);
    } catch (error) {
      console.error('Error updating address:', error);
    }
  };

  const handleAddressSelection = (e) => {
    console.log("object", e.target.value)
    setSelectedAddress(e.target.value);
  };

  const handleChangeAddress = async (e) => {
    e.preventDefault();
    // fetchAddresses();
    setShowEditForm(false);
    setShowChangeForm(false);
  }

  const handleAddressSelectionSubmit = async (e) => {
    const token = localStorage.getItem('token');

    e.preventDefault();
    try {
      await axios.put(`https://node.instasales.in/api/v1/address/${selectedAddress}`, {
                    headers: {
              'Authorization': `Bearer ${token}`
            }
      });
      fetchAddresses();
      setShowChangeForm(false);
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  const fetchAddressDetails = async (pincode) => {
    try {
      const response = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`);
      const data = response.data[0];
      console.log("data", data)
      if (data.Status === "Success" && data.PostOffice.length > 0) {
        const { District, block, State, Pincode } = data.PostOffice[0];
        setFormData({ ...formData, town: District || block, city: District || block, state: State, postalCode: Pincode });
      } else {
        console.error("Invalid pincode or no data found.");
      }
    } catch (error) {
      console.error('Error fetching address details:', error);
    }
  };

  const [editAddressId, setEditAddressId] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    houseNo: '',
    street: '',
    landmark: '',
    town: '',
    city: '',
    state: '',
    postalCode: ''
  });



  const handleUpdateAddress = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.put(`https://node.instasales.in/api/v1/address/${editAddressId}`, formData, {
                    headers: {
              'Authorization': `Bearer ${token}`
            },
      });
      if (response.data.success) {
        fetchAddresses();
        setFormData({
          name: '',
          email: '',
          phoneNumber: '',
          houseNo: '',
          street: '',
          landmark: '',
          town: '',
          city: '',
          state: '',
          postalCode: ''
        });
        swal("Success!", "Address updated successfully!", "success");
        fetchAddresses();
        setShowEditForm(false);
        setShowChangeForm(false);
      }
    } catch (error) {
      console.error('Error updating address:', error);
      swal("Oops!", "Something went wrong while updating the address!", "error");
    }
  };
  const handlepromochange = (e) => {
    setPromoApplied(false)
    setPromocode(e.target.value)
  }
  const handlePlaceOrder = async () => {
    const token = localStorage.getItem('token');

    setShowLoader(true); // Show the loader modal

    const orderItems = products.map(product => ({

      name: product.product.name,
      price: product.variant?.selling_price,
      quantity: product.quantity,
      image: product.product.images[0]?.url,
      product: product.product._id,
      variation: product.variant,
      brand_id:product.product.brand_id
    }));

    const shippingInfo = {
      address: addresses[0]?.houseNo + ', ' + addresses[0]?.street + ', ' + addresses[0]?.landmark,
      city: addresses[0]?.city,
      state: addresses[0]?.state,
      country: 'India',
      pinCode: addresses[0]?.postalCode,
      phoneNo: addresses[0]?.phoneNumber,
    };

    const orderData = {
      shippingInfo,
      orderItems,
      itemsPrice: totalPrice,
      taxPrice: 0,
      shippingPrice: 40,
      totalPrice: totalPrice + 40,
      user: user._id, // Assuming you have user context to get the logged-in user's ID
    };
console.log("order data to process",orderData)
    try {
      const response = await axios.post('https://node.instasales.in/api/v1/order/new', orderData, {
                    headers: {
              'Authorization': `Bearer ${token}`
            },
      });
      console.log("response",response)
      if (response.data.success) {
        setShowLoader(false); // Hide the loader modal
        navigate('/order-confirmation', {
            state: { orders: response.data.orders } // Pass orders directly
        });
        clearProducts();
    }
    } catch (error) {
      setShowLoader(false);

      console.error('Error placing order:', error);
    }
  };

  const handleBuyNow = async () => {
    const token = localStorage.getItem('token');

    if (!user) {
      navigate('/auth2');
    } else {
      try {
        const addressResponse = await axios.get('https://node.instasales.in/api/v1/address', {
                      headers: {
              'Authorization': `Bearer ${token}`
            }
        });
        if (!addressResponse.data.addresses) {
          navigate('/add-address');
        } else {
          // navigate('/product-summary', { state: { products, selectedVariation, quantity } });
          handlePlaceOrder();
        }
      } catch (error) {
        console.error('Error checking address:', error);
      }
    }
  };

  console.log("address data", addressForm)
  console.log("form data", formData)
  if (loading) {
    return (
      <div className="cart-loading">
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  }

  console.log("products in cart", products)
  const handleApplyPromoCode = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post('https://node.instasales.in/api/v1/brand/promocode/apply', {
        code: promoCode,
        cartItems: products.map(item => ({
          productId: item.product._id,
          variantName: item.variant.name,
          brandId: item.product.brand_id,
          quantity: item.quantity
        }))
      }, {             headers: {
              'Authorization': `Bearer ${token}`
            } });

      const { discountAmount, applicableBrand } = response.data;



      // Calculate eligible cart value for the brand associated with the promocode
      const eligibleCartValue = calculateEligibleCartValue(applicableBrand);

      const updatedProducts = products.map(item => {
        if (item.product.brand_id === applicableBrand) {
          const proportion = (item.variant.selling_price * item.quantity) / calculateEligibleCartValue(applicableBrand);
          const itemDiscount = proportion * discountAmount;
          const discountedPrice = item.variant.selling_price - (itemDiscount / item.quantity);
          return {
            ...item,
            variant: {
              ...item.variant,
              discountedPrice: discountedPrice.toFixed(2)
            }
          };
        }
        return item;
      });


      console.log("updted product to update ", updatedProducts)
      // Update the state with the updated products

      // Calculate the new total price after applying the discount on the eligible cart value
      const updatedTotalPrice = calculateTotalPriceWithDiscount(eligibleCartValue, discountAmount);

      // Set the state with the updated total price
      setTotalPrice(updatedTotalPrice);

      setProducts(updatedProducts);
      setPromoApplied(true);
      setApplicableBrand(applicableBrand);
    } catch (error) {
      console.error('Error applying promocode:', error);
      setPromoApplied(false);
    }
  };

  // Helper function to calculate the eligible cart value for the given brand
  const calculateEligibleCartValue = (brandId) => {
    return products.reduce((total, item) => {
      if (item.product.brand_id === brandId) {
        return total + (item.variant.selling_price * item.quantity);
      }
      return total;
    }, 0);
  };

  // Helper function to calculate the total price with the discount applied to the eligible cart value
  const calculateTotalPriceWithDiscount = (eligibleCartValue, discount) => {
    const totalPriceBeforeDiscount = products.reduce((total, item) => {
      return total + (item.variant.selling_price * item.quantity);
    }, 0);

    return totalPriceBeforeDiscount - discount;
  };






  // const totalPrice = cart?.products.reduce((acc, item) => acc + (item?.variant.selling_price * item?.quantity), 0) || 0;

  const handleHoverDivClick = (link) => {
    window.location.href = link;
  };
  const getEstimatedDeliveryDate = () => {
    const currentDate = new Date();
    const estimatedDate = new Date(currentDate.getTime() + (15 * 24 * 60 * 60 * 1000));
    return estimatedDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };
  return (
    <div className="cart">
      <OrderProcessingModal show={showLoader} onHide={() => setShowLoader(false)} />

      {empty ?
        <div className="cart-empty">
          <p className="cart-h">Your Cart</p>
          <Container className="cart-empty-container">
            <img src={sc} className="cart-empty-img" />
            <div className="cart-empty-h">You Don’t Have Anything in Your Cart</div>
            <div className="cart-empty-p">Explore and add items</div>
            <Button className="cart-empty-btn">Explore</Button>
          </Container>
        </div>
        :
        <>
          <p className="cart-h">Your Cart <span className="bold">({cart.products.length} Items)</span></p>
          <Row className="cart-row">
            <Col lg={8} className="cart-col-1">
              <Row className="cart-delivery">

                {(!showEditForm && !showChangeForm) && (
                  <>
                    <Col lg={9} className="cart-address">
                      <div>Deliver to: <span className="bold">{addresses.find(addr => addr._id === selectedAddress)?.name}</span></div>
                      <div className="cart-address-text">{addresses.find(addr => addr._id === selectedAddress)?.houseNo}, {addresses.find(addr => addr._id === selectedAddress)?.street}, {addresses.find(addr => addr._id === selectedAddress)?.landmark}</div>
                      <div className="cart-address-text">{addresses.find(addr => addr._id === selectedAddress)?.city}, {addresses.find(addr => addr._id === selectedAddress)?.state}, India {addresses.find(addr => addr._id === selectedAddress)?.postalCode}</div>
                    </Col>
                    <Col lg={3} className="cart-delivery-controls">
                      <Button className="cart-edit" onClick={() => handleEditAddress(selectedAddress)}><FontAwesomeIcon icon={faPenToSquare} /> Edit</Button>
                      <Button className="cart-change" onClick={handleChangeAddressClick}><FontAwesomeIcon icon={faRotate} /> Change</Button>
                    </Col>
                  </>
                )}


                {showEditForm && (
                  <>
                    <div className="edit-your">
                      Edit Your Address
                    </div>
                    <Form onSubmit={handleAddressFormSubmit} className='form-changes'>
                      <Row className="address-form-top">
                        <Col className='edit-col'>
                          <InputGroup as={Col} className="profile-input  profile-input2">
                            <InputGroup.Text>Full Name</InputGroup.Text>
                            <Form.Control type="text" name="name" value={formData.name} onChange={handleAddressFormChange} />
                          </InputGroup>
                          <InputGroup as={Col} className="profile-input profile-input2">
                            <InputGroup.Text>Email</InputGroup.Text>
                            <Form.Control type="mail" name="email" value={formData.email} onChange={handleAddressFormChange} />
                          </InputGroup>
                          <InputGroup className="profile-input profile-input2">
                            <InputGroup.Text>+91</InputGroup.Text>
                            <Form.Control type="number" name="phoneNumber" value={formData.phoneNumber} onChange={handleAddressFormChange} />
                          </InputGroup>
                        </Col>
                        <Col className='edit-col'>
                          <InputGroup className="profile-input profile-input2">
                            <InputGroup.Text>Pincode</InputGroup.Text>
                            <Form.Control type="text" name="postalCode" value={formData.postalCode} onChange={handleAddressFormChange} />
                          </InputGroup>
                          <InputGroup className="profile-input profile-input2">
                            <InputGroup.Text>Town / City</InputGroup.Text>
                            <Form.Control type="text" name="city" value={formData.city} onChange={handleAddressFormChange} />
                          </InputGroup>
                          <InputGroup className="profile-input profile-input2">
                            <InputGroup.Text>State</InputGroup.Text>
                            <Form.Control type="text" placeholder="State" name="state" value={formData.state} onChange={handleAddressFormChange} />
                          </InputGroup>
                        </Col>


                      </Row>
                      <Row className='form-bottom'>

                        <InputGroup className="profile-input profile-input2 profile-input3 ">
                          <InputGroup.Text>Flat, House no. , Apartment</InputGroup.Text>
                          <Form.Control type="text" name="houseNo" value={formData.houseNo} onChange={handleAddressFormChange} />
                        </InputGroup>
                        <InputGroup className="profile-input profile-input2 profile-input3">
                          <InputGroup.Text>Area, Street, Sector, Village</InputGroup.Text>
                          <Form.Control type="text" name="street" value={formData.street} onChange={handleAddressFormChange} />
                        </InputGroup>
                        <InputGroup className="profile-input profile-input2 profile-input3">
                          <InputGroup.Text>Landmark</InputGroup.Text>
                          <Form.Control type="text" name="landmark" value={formData.landmark} onChange={handleAddressFormChange} />
                        </InputGroup>
                      </Row>
                      <Button variant="primary" type="submit" className='save-address' onClick={handleUpdateAddress}>
                        Save
                      </Button>
                    </Form>
                  </>
                )}

                {showChangeForm && (
                  <Row className="address-form">
                    {/* <Form onSubmit={handleAddressSelectionSubmit}> */}
                    <Form >
                      {addresses.map((address) => (
                        <Form.Group key={address._id} controlId={`address-${address._id}`} className={`change-form ${addresses.length > 1 ? 'multiple-addresses' : ''}`}>
                          <div className="deliver-to">
                            <div>Deliver to: <span className="bold">{address.name}</span></div>
                            <div className="cart-address-text">{address.houseNo}, {address.street}, {address?.landmark}</div>
                            <div className="cart-address-text">{address?.city}, {address?.state}, India {address?.postalCode}</div>
                          </div>
                          <Form.Check
                            type="radio"
                            name="address"
                            value={address._id}
                            checked={selectedAddress === address._id}
                            onChange={handleAddressSelection}
                          />
                        </Form.Group>
                      ))}

                      <Button variant="primary" type="submit" className='save-address' onClick={handleChangeAddress}>
                        Save
                      </Button>
                    </Form>
                  </Row>
                )}
              </Row>


              <Row className="cart-items">
                {products.map((item, index) => (
                  <Card className="cart-item" key={index}>
                    <Row className="cart-item-details">
                      <Col lg={3} className="cart-item-img-container" onClick={() => handleHoverDivClick(`/product/${item.product.brand_slug}/${item.product._id}`)}>
                        <img src={item?.product?.images[0]?.url} className="cart-item-img" />
                      </Col>
                      <Col lg={9} className="cart-item-text">
                        <div className="cart-item-text-h">{item?.product?.name}</div>
                        <div className="cartitem-varient">Variant : {item?.variant?.name} </div>
                        <div className="cart-item-text-promo home-products-desc">{item?.product?.description}</div>
                        {item.product.brand_id === applicableBrand && promoApplied && (
                          <>
                            <div className="cart-item-text-promo">
                              APPLIED PROMOCODE: {promoCode} - Discounted Price: ₹{item.variant.discountedPrice}
                            </div>
                          </>
                        )}
                        {/* <div className="cart-item-text-promo">APPLIED PROMOCODE : 50% of on the skin care product</div> */}
                        {!promoApplied || item.product.brand_id !== applicableBrand ? (
                          <>
                            <div className="cart-item-price">₹{item.variant.selling_price}</div>
                          </>
                        ) : (
                          <>
                            <div className="cart-item-price">
                              <span className="cart-item-og-price">₹{item.variant.selling_price}</span> ₹{item.variant.discountedPrice}
                            </div>
                            <Button className="cart-item-btn-promo">Change promocode</Button>
                          </>
                        )}

                        <p className="cart-item-price">
                          <Button className="cart-item-sub" onClick={() => handleDecrement(item.product._id, item.quantity)}>-</Button>
                          <span className="cart-item-count">{item.quantity}</span>
                          <Button className="cart-item-add" onClick={() => handleIncrement(item.product._id, item.quantity)}>+</Button>
                          <Button className="cart-item-remove" onClick={() => handleRemoveFromCart(item.product._id)}>Remove</Button>
                        </p>
                      </Col>

                      <Col lg={9} className="cart-item-text">


                      </Col>
                    </Row>
                  </Card>
                ))}
              </Row>
            </Col>
            <Col lg={4} className="cart-col-2">
              <p className="cart-delivery-text">
                Estimated Delivery : <span className="cart-delivery-date">{getEstimatedDeliveryDate()}</span>
              </p>
              <Form.Label className="cart-promo-text bold">APPLY PROMOCODE</Form.Label>
              <InputGroup>
                <Form.Control
                  className="cart-promo-box"
                  placeholder="Add promo code"
                  value={promoCode}
                  onChange={(e) => handlepromochange(e)}
                />
                <Button className="cart-promo-btn" onClick={handleApplyPromoCode}>
                  <FontAwesomeIcon icon={faPaperPlane} />
                </Button>
              </InputGroup>
              <div className="cart-price">
                <div className="cart-price-text bold">Price Details</div>
                <div><span className="cart-price-left">Price ({cart.products.length} item{cart.products.length > 1 ? 's' : ''})</span><span className="cart-price-right">₹{totalPrice}</span></div>
                <div><span className="cart-price-left">Delivery Fee</span><span className="cart-price-right">₹40</span></div>
                <hr className="custom-hr-1"></hr>
                <div><span className="cart-price-left bold">Total Amount</span><span className="cart-price-right bold">₹{totalPrice + 40}</span></div>
                <Button className="cart-btn" onClick={handleBuyNow}>Place Order</Button>
              </div>
            </Col>
          </Row>
        </>
      }
    </div >
  );
};

export default Cart;
