import React, { useEffect, useState, Suspense, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { HashRouter, BrowserRouter, Routes, Route, Link, useNavigate } from "react-router-dom";
import { Button, Container, Col, Dropdown, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faBell, faCartShopping, faChevronDown, faEnvelope, faHeart, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Home, Brands, Creator, Creators, Product, Categories, All, Cart, Wishlist, Profile, Notifications, Terms, Privacy, About, CreatorPartnership, BrandCollaboration, Auth } from './components'
import profile from './media/profile.jpeg'
import place from './media/place.jpg'
import userimg from './media/user.png'
import axios from "axios"
import { gapi } from 'gapi-script';
import { useSelector } from 'react-redux'
import { UserProvider } from "./components/UserContext";
import { ProductProvider } from './components/ProductContext';
import { CSpinner, useColorModes } from '@coreui/react'
import './scss/style.scss'
import { UserContext } from "./components/UserContext";
import ProtectedRoute from "./components/Route/UserProtectedRoute";
import Brand from "./components/Brand/Brand";
import SearchResults from "./components/Search result/SearchResult";
import AddAddress from "./components/Add Address/AddAddress";
import ProductSumary from "./components/Product Summary/ProductSumary";
import AuthBuyNow from "./components/Auth Buynow/AuthBuyNow";
import AddBrand from "./components/add brand/AddBrand";
import RegisterCreator from "./components/Add creator/AddCreatr";
import CategoriesClothings from "./components/Categories/CaregoryClothing";
import AllSubcategory from "./components/Categories/AllSubcategory";
import AllSubSubCategory from "./components/Categories/AllSubSubcategory";
import OrderConfirmation from "./components/Product Summary/OrderConfirmation";
import BrandCategory from "./components/Brand/BrandCategory";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import CreatorProduct from "./components/Product/CreatorProduct";
import CreatorCategory from "./components/Creator/CreatorCategory";
import OrderConfirmationMultiple from "./components/Product Summary/OrderConfirmationMultiple";
import ScrollToTop from './ScrollToTop';

// Containers
const DefaultLayout = React.lazy(() => import('./components/layout/DefaultLayout'))

// // Pages
const Login = React.lazy(() => import('../src/components/Admin/views/pages/login/Login'))
const Register = React.lazy(() => import('../src/components/Admin/views/pages/register/Register'))
const Page404 = React.lazy(() => import('../src/components/Admin/views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('../src/components/Admin/views/pages/page500/Page500'))


function App() {

  const clientId = '1039353997484-lbnfp3o3sbmdtorl6dl669lp99locmfv.apps.googleusercontent.com'
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: ""
      })
    }
    gapi.load("client:auth2", start)
  }, [])

  // const { isColorModeSet, setColorMode } = useColorModes('coreui-free-react-admin-template-theme')
  // const storedTheme = useSelector((state) => state.theme)

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.href.split('?')[1])
  //   const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0]
  //   if (theme) {
  //     setColorMode(theme)
  //   }

  //   if (isColorModeSet()) {
  //     return
  //   }

  //   setColorMode(storedTheme)
  // }, [])




  const Header = () => {

    const { user, setUser } = useContext(UserContext);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // const handleCategoryClick = async (category) => {
    //   try {
    //     const response = await axios.get(`https://node.instasales.in/api/v1/products?category=${category}`);
    //     console.log(response.data);
    //   } catch (error) {
    //     console.error('Error fetching products:', error);
    //   }
    // };

    const handleLogout = async () => {
      try {
        const token = localStorage.getItem('token');
        await axios.get('https://node.instasales.in/api/v1/logout', {
          headers: {
            'Authorization': `Bearer ${token}`
          }});
        setUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.href = '/'
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };


    const handleCategoryClick = (category) => {
      window.location.href = (`/categories/${category}`);
    };

    const handleHoverDivClick = (link) => {
      window.location.href = link;
    };

    const [notifications, setNotifications] = useState([]);
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
    const [cartItemCount, setCartItemCount] = useState(0);

    useEffect(() => {
      const fetchNotifications = async () => {
        const token = localStorage.getItem('token');

        try {
          const response = await axios.get('https://node.instasales.in/api/v1/notifications', {
                        headers: {
              'Authorization': `Bearer ${token}`
            },
          });
          setNotifications(response.data.notifications);
          const unreadCount = response.data.notifications.filter(notification => !notification.isRead).length;
          setUnreadNotificationsCount(unreadCount);
        } catch (error) {
          console.error('Error fetching notifications:', error);
        }
      };

      const fetchCartItems = async () => {
        const token = localStorage.getItem('token');

        try {
          const response = await axios.get('https://node.instasales.in/api/v1/cart', {
                        headers: {
              'Authorization': `Bearer ${token}`
            },
          });
          setCartItemCount(response.data.cart.products.length);
        } catch (error) {
          console.error('Error fetching cart items:', error);
        }
      };

      fetchNotifications();
      fetchCartItems();
    }, []);

    const handleMarkAsRead = async (notificationId) => {
      const token = localStorage.getItem('token');

      try {
        await axios.put(`https://node.instasales.in/api/v1/notifications/${notificationId}/read`, {}, {
                      headers: {
              'Authorization': `Bearer ${token}`
            },
        });

        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification._id === notificationId ? { ...notification, isRead: true } : notification
          )
        );

        setUnreadNotificationsCount(prevCount => prevCount - 1);
      } catch (error) {
        console.error('Error marking notification as read:', error);
      }
    };

    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value);
    };

    const handleSearchSubmit = (e) => {
      e.preventDefault();
      if (searchQuery.trim()) {
        navigate(`/search?q=${searchQuery}`);
      }
    };
    const [imageSrc, setImageSrc] = useState('');

    useEffect(() => {
      const token = localStorage.getItem('token');
      const fetchProfilePhoto = async () => {
        try {
          const response = await axios.get('https://node.instasales.in/api/v1/get-profile-photo', {
            headers: {
              'Authorization': `Bearer ${token}`
            }});

          if (response.data.success) {
            setImageSrc(response.data.url);
          } else {
            console.error('Failed to fetch profile photo:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching profile photo:', error);
        }
      };

      fetchProfilePhoto();
    }, []);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleToggle = (isOpen) => {
      setIsDropdownOpen(isOpen);
    };

    const handleNavigateToOrders = () => {
      navigate("/profile#order");
    };


    return (
      <div className="header">
        <Container style={{ display: "block" }}>
          <Row className="header-content">
            <Col lg={2}>
              <Link to={'/'} className="brand-name">INSTASALES</Link>
            </Col>
            <Col lg={4}>
              <InputGroup className="header-search" onSubmit={handleSearchSubmit}>
                <Form.Control
                  type="search"
                  placeholder="Brands, Creators, Products"
                  className="header-search-input-box"
                  aria-label="Search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  onKeyPress={(e) => e.key === 'Enter' && handleSearchSubmit(e)}
                  autoComplete="off"  
                />
                <Button className="header-search-button" onClick={handleSearchSubmit}>
                  <InputGroup.Text className="header-search-logo">
                    <FontAwesomeIcon icon={faMagnifyingGlass} size="xs" />
                  </InputGroup.Text>
                </Button>
              </InputGroup>
            </Col>
            <Col lg={6}>
              <Button className="header-btn btn1" onClick={handleShow}>Category <FontAwesomeIcon className="icon-select" icon={faChevronDown} /></Button>
              <Button className="header-btn btn2" onClick={() => handleHoverDivClick('/brands')}>Brands</Button>
              <Dropdown
                className="header-dd"
                onToggle={handleToggle}
              >
                <Dropdown.Toggle className={`header-btn btn-3 ${isDropdownOpen ? 'dropdown-open' : ''}`}>

                  <FontAwesomeIcon icon={faBell} size="lg" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="header-dd-content">
                  {notifications.length == 0 ? (
                    <div className="header-dd-txt">No Notification found</div>
                  ) : (
                    <>
                      {notifications.slice(0, 4).map((notification) => (
                        <Dropdown.Item
                          key={notification._id}
                          className={`header-dd-btn ${notification.isRead ? '' : 'new'}`}
                          onClick={() => handleMarkAsRead(notification._id)}
                        >
                          <div className="header-dd-h">{notification.heading}</div>
                          <div className="header-dd-txt">{notification.description}</div>
                        </Dropdown.Item>

                      ))}
                      <Button className="header-dd-all" href="/notifications">View all</Button>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <Link to={'/wishlist'}><Button className="header-btn btn3"><FontAwesomeIcon icon={faHeart} size="lg" /></Button></Link>
              <Link to={'/cart'}> <Button className="header-btn btn3"><FontAwesomeIcon icon={faCartShopping} size="lg" />
                {cartItemCount > 0 && <span className="badge badge-cart">{cartItemCount}</span>}
              </Button></Link>
              <Dropdown className="header-dd1">
                {user !== null ?
                  <>
                    <Dropdown.Toggle className="header-btn profile">
                      <img className="header-profile-img" src={imageSrc ? imageSrc : place} alt="" /> {user ? user.name : "Hello user"} <FontAwesomeIcon className="icon-select" icon={faChevronDown} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="header-dd1-content">
                      <Dropdown.Item href={user ? "/profile" : "/auth"} className="header-dd1-btn">Profile<FontAwesomeIcon className="header-dd1-icon" icon={faArrowUp} /></Dropdown.Item>
                      <Dropdown.Item className="header-dd1-btn" onClick={() => handleNavigateToOrders()}>Your Orders<FontAwesomeIcon className="header-dd1-icon" icon={faArrowUp} /></Dropdown.Item>
                      <Dropdown.Item onClick={handleLogout} className="header-dd1-btn">Log Out<FontAwesomeIcon className="header-dd1-icon" icon={faArrowUp} /></Dropdown.Item>
                    </Dropdown.Menu>
                  </>
                  :
                  <>
                    <div className="sign-in" onClick={() => handleHoverDivClick('/auth')}>
                      <div className="sign-icon">
                        <img src={userimg} alt="" />
                      </div>
                      <div className="sign-text">
                        Sign In

                      </div>
                    </div>
                  </>}

              </Dropdown>
            </Col>
          </Row>
        </Container>
        <Modal className="header-modal" show={show} onHide={handleClose} backdrop="static" size="xl">
          <Modal.Header className="header-modal-h" closeButton />
          <Modal.Body className="header-modal-content">
            <Row className="header-modal-row">
              <Col lg={3} className="header-modal-col">
                <Button className="header-modal-btn htb-1" onClick={() => handleCategoryClick('Skin Care')}>Skin Care</Button>
              </Col>
              <Col lg={3} className="header-modal-col">
                <Button className="header-modal-btn htb-2" onClick={() => handleCategoryClick('Art')}>Art</Button>
              </Col>
              <Col lg={3} className="header-modal-col">
                <Button className="header-modal-btn htb-3" onClick={() => handleHoverDivClick('/categories/Clothings')}>Clothing</Button>
              </Col>
              <Col lg={3} className="header-modal-col">
                <Button className="header-modal-btn htb-4" onClick={() => handleCategoryClick('Accessories')}>Accessories</Button>
              </Col>
            </Row>
            <br />
            <Row className="header-modal-row">
              <Col lg={3} className="header-modal-col">
                <Button className="header-modal-btn htb-5" onClick={() => handleCategoryClick('Home & Living')} >Home & Living</Button>
              </Col>
              <Col lg={3} className="header-modal-col">
                <Button className="header-modal-btn htb-6" onClick={() => handleCategoryClick('Foods & Beverages')}>Foods & Beverages</Button>
              </Col>
              <Col lg={3} className="header-modal-col">
                <Button className="header-modal-btn htb-7" onClick={() => handleCategoryClick('Kitchen')}>Kitchen</Button>
              </Col>
              <Col lg={3} className="header-modal-col">
                <Button className="header-modal-btn htb-8" onClick={() => handleCategoryClick('Stationery')}>Stationery</Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    )
  }

  const Footer = () => {

    return (
      <div className="footer">
        <Container className="footer-content">
          <Row>
            <Col lg={2}>
              <div className="footer-h">About.</div>
              <Link to={'/about-us'}> <Button className="footer-btn">About Us</Button></Link>
              <Link to={'/terms'}> <Button className="footer-btn">Terms & Condition</Button></Link>
              <Link to={'/privacy-policy'}> <Button className="footer-btn">Privacy Policy</Button></Link>
              <Link to={''}> <Button className="footer-btn">Help & Support</Button></Link>
            </Col>
            <Col lg={3}>
              <div className="footer-h">Join Us.</div>
              <Link to={'/creator-partnership'}><Button className="footer-btn">Creators Partnership Program</Button></Link>
              <Link to={'/brand-collaboration'}> <Button className="footer-btn">Brand Collaboration</Button></Link>
            </Col>
            <Col lg={2}>
              <div className="footer-h">Contact Us.</div>
              <Button className="footer-btn"><FontAwesomeIcon icon={faEnvelope} /> instasales@info.com</Button>
              <div className="footer-socials">
                <Button className="footer-btn"><FontAwesomeIcon size="2x" icon={faInstagram} /></Button>
                <Button className="footer-btn"><FontAwesomeIcon size="2x" icon={faTwitter} /></Button>
                <Button className="footer-btn"><FontAwesomeIcon size="2x" icon={faFacebook} /></Button>
              </div>
            </Col>
            <Col lg={5}>
              <div className="brand-name">INSTASALES</div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  return (
    <ProductProvider>
      <BrowserRouter>
      <ScrollToTop />

        <Suspense
          fallback={
            <div className="pt-3 text-center">
              <CSpinner color="primary" variant="grow" />
            </div>
          }
        >
          <div className="App">
            <UserProvider>
              <Routes>
                <Route
                  path="/"
                  element={[<Header key="header" />, <Home key="home" />, <Footer key="footer" />]}
                />
                <Route
                  path="/brands"
                  element={[<Header key="header" />, <Brands key="Brands" />, <Footer key="footer" />]}
                />
                <Route
                  path="/brand/:slug"
                  element={[<Header key="header" />, <Brand key="Brands" />, <Footer key="footer" />]}
                />
               
                <Route
                  path="/brand/:slug/category/:Category"
                  element={[<Header key="header" />, <BrandCategory key="BrandCategory" />, <Footer key="footer" />]}
                />
                {/* <Route
                   path="/brand/:id/subcategory/:subCategory"
                  element={[<Header key="header" />, <Brand key="Brands" />, <Footer key="footer" />]}
                /> */}
                 <Route
                  path="/creator/:slug"
                  element={[<Header key="header" />, <Creator key="Creator" />, <Footer key="footer" />]}
                />
                 <Route
                  path="/creator/:slug/category/:Category"
                  element={[<Header key="header" />, <CreatorCategory key="CreatorCategory" />, <Footer key="footer" />]}
                />


                <Route
                  path="/creators"
                  element={[<Header key="header" />, <Creators key="Creators" />, <Footer key="footer" />]}
                />
                <Route
                  path="/order-confirmation/:orderId"
                  element={[<Header key="header" />, <OrderConfirmation key="OrderConfirmation" />, <Footer key="footer" />]}
                />
                <Route
                  path="/order-confirmation"
                  element={[<Header key="header" />, <OrderConfirmationMultiple key="OrderConfirmationMultiple" />, <Footer key="footer" />]}
                />
                <Route
                  path="/categories/:categoryName" exact
                  element={[<Header key="header" />, <Categories key="Categories" />, <Footer key="footer" />]}
                />
                <Route
                  path="/categories/Clothings" exact
                  element={[<Header key="header" />, <CategoriesClothings key="CategoriesClothings" />, <Footer key="footer" />]}
                />
                <Route
                  path="/subcategories-all/:categoryName/:subSubCategory/" exact
                  element={[<Header key="header" />, <All key="All" />, <Footer key="footer" />]}
                />
                <Route
                  path="/subcategories-all/:subcategoryName" exact
                  element={[<Header key="header" />, <AllSubcategory key="All" />, <Footer key="footer" />]}
                />
                <Route
                  path="/Clothings/:subcategoryName/:subsubcategory" exact
                  element={[<Header key="header" />, <AllSubSubCategory key="All" />, <Footer key="footer" />]}
                />
                <Route
                  path="/cart"
                  element={[<Header key="header" />, <Cart key="Cart" />, <Footer key="footer" />]}
                />
                <Route
                  path="/wishlist"
                  element={[<Header key="header" />, <Wishlist key="Wishlist" />, <Footer key="footer" />]}
                />
                <Route
                  path="/notifications"
                  element={[<Header key="header" />, <Notifications key="Notifications" />, <Footer key="footer" />]}
                />
                <Route
                  path="/terms"
                  element={[<Header key="header" />, <Terms key="Terms" />, <Footer key="footer" />]}
                />
                <Route
                  path="/privacy-policy"
                  element={[<Header key="header" />, <Privacy key="Privacy" />, <Footer key="footer" />]}
                />
                <Route
                  path="/about-us"
                  element={[<Header key="header" />, <About key="About" />, <Footer key="footer" />]}
                />
                <Route
                  path="/creator-partnership"
                  element={[<Header key="header" />, <CreatorPartnership key="CreatorPartnership" />, <Footer key="footer" />]}
                />
                <Route
                  path="/brand-collaboration"
                  element={[<Header key="header" />, <BrandCollaboration key="BrandCollaboration" />, <Footer key="footer" />]}
                />
               
                <Route
                  path="/product/:brandslug/:id"
                  element={[<Header key="header" />, <Product key="Product" />, <Footer key="footer" />]}
                />
                <Route
                  path="/product/:creatorslug/:id"
                  element={[<Header key="header" />, <CreatorProduct key="Product" />, <Footer key="footer" />]}
                />
                <Route
                  path="/profile"
                  element={[<Header key="header" />, <Profile key="Profile" />, <Footer key="footer" />]}
                />
                <Route
                  path="/search"
                  element={[<Header key="header" />, <SearchResults key="SearchResults" />, <Footer key="footer" />]}
                />
                <Route
                  path="/add-address"
                  element={[<Header key="header" />, <AddAddress key="AddAddress" />, <Footer key="footer" />]}
                />
                <Route
                  path="/auth2"
                  element={[<Header key="header" />, <AuthBuyNow key="AuthBuyNow" />, <Footer key="footer" />]}
                />
                <Route
                  path="/product-summary"
                  element={[<Header key="header" />, <ProductSumary key="ProductSumary" />, <Footer key="footer" />]}
                />
                <Route
                  path="/add-brand"
                  element={[<Header key="header" />, <AddBrand key="AddBrand" />, <Footer key="footer" />]}
                />
                <Route
                  path="/add-creator"
                  element={[<Header key="header" />, <RegisterCreator key="RegisterCreator" />, <Footer key="footer" />]}
                />

                <Route
                  path="/auth"
                  element={[<Auth key="Auth" />]}
                />
                <Route exact path="/login" name="Login Page" element={<Login />} />
                <Route exact path="/register" name="Register Page" element={<Register />} />
                <Route exact path="/404" name="Page 404" element={<Page404 />} />
                <Route exact path="/500" name="Page 500" element={<Page500 />} />
                <Route path="/admin/*" name="Home" element={<DefaultLayout />} />
                <Route  path="/password/reset/:token" element={<ResetPassword/>} />

                {/* <Route
          isAdmin={true}
          exact
          path="/admin/dashboard"
          element={[<Dashboard/>]}
        />
        <Route
          exact
          path="/admin/products"
          isAdmin={true}
          element={[<ProductList/>]}
        />
        <Route
          exact
          path="/admin/product"
          isAdmin={true}
          component={NewProduct}
        />

        <Route
          exact
          path="/admin/product/:id"
          isAdmin={true}
          component={UpdateProduct}
        />
        <Route
          exact
          path="/admin/orders"
          isAdmin={true}
          component={OrderList}
        />

        <Route
          exact
          path="/admin/order/:id"
          isAdmin={true}
          component={ProcessOrder}
        />
        <Route
          exact
          path="/admin/users"
          isAdmin={true}
          component={UsersList}
        />

        <Route
          exact
          path="/admin/user/:id"
          isAdmin={true}
          component={UpdateUser}
        /> */}
                {/* <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/dashboard"
          component={Dashboard}
        />
        <ProtectedRoute
          exact
          path="/admin/products"
          isAdmin={true}
          component={ProductList}
        />
        <ProtectedRoute
          exact
          path="/admin/product"
          isAdmin={true}
          component={NewProduct}
        />

        <ProtectedRoute
          exact
          path="/admin/product/:id"
          isAdmin={true}
          component={UpdateProduct}
        />
        <ProtectedRoute
          exact
          path="/admin/orders"
          isAdmin={true}
          component={OrderList}
        />

        <ProtectedRoute
          exact
          path="/admin/order/:id"
          isAdmin={true}
          component={ProcessOrder}
        />
        <ProtectedRoute
          exact
          path="/admin/users"
          isAdmin={true}
          component={UsersList}
        />

        <ProtectedRoute
          exact
          path="/admin/user/:id"
          isAdmin={true}
          component={UpdateUser}
        /> */}
              </Routes>
            </UserProvider>
          </div>
        </Suspense>
      </BrowserRouter>
    </ProductProvider>
  )
}

export default App;
