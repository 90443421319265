import React, { useState, useRef, useEffect } from 'react';
import swal from 'sweetalert';
import Carousel from "react-multi-carousel";
import { Button, Card, Col, Row, Modal, Form } from "react-bootstrap";
import cardbg from "../../media/card.png";
import { faFilter, faCaretLeft, faCaretRight, faBell, faBellSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import "./brand.css";
import BrandSeectedCategory from './BrandSeectedCategory';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import Slider from '@mui/material/Slider';
import { Helmet } from 'react-helmet';


const Brand = () => {
    const { slug } = useParams();
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [brandLoading, setBrandLoading] = useState(true); // Add brand loading state
    const [productsLoading, setProductsLoading] = useState(true); // Add products loading state
    const [selectedCubcategory, setSelectedCubcategory] = useState(null)
    const [popularProducts, setPopularProducts] = useState([]);
    const [popularLoading, setPopularLoading] = useState(true);
    const [selectedFilters, setSelectedFilters] = useState({
        subCategory: '',
        sort: '',
        discount: '',
        brands: [],
        priceRange: [0, 800],
        discountRange: [10, 95]
    });
    const [maxPrice, setMaxPrice] = useState(1000);

    const carouselRefs = useRef({});
    const popularCarouselRef = useRef(null);

    const goToPrevPopular = () => {
        popularCarouselRef.current.previous();
    };

    const goToNextPopular = () => {
        popularCarouselRef.current.next();
    };


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const alert1 = () => {
        handleClose1();
        swal("Yayyy!", "Message sent successfully!", "success");
    }
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const [brand, setBrand] = useState(null);

    useEffect(() => {
        const fetchCreatorData = async () => {
            try {
                const response = await axios.get(`https://node.instasales.in/api/v1/brand/brand/${slug}`);
                setBrand(response.data.brand);
                checkSubscriptionStatus();
                setBrandLoading(false); // Set brand loading to false after data is fetched
            } catch (error) {
                console.error('Error fetching creator data:', error);
                setBrandLoading(false); // Set brand loading to false in case of error
            }
        };

        fetchCreatorData();
        checkSubscriptionStatus();

    }, [slug]);

    const checkSubscriptionStatus = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(`https://node.instasales.in/api/v1/check-subscription-status?subscribeToId=${brand?._id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setIsSubscribed(response.data.isSubscribed);
        } catch (error) {
            console.error('Error checking subscription status:', error);
        }
    };
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState({});
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');

    const [products, setProducts] = useState([]);
    const [groupedProducts, setGroupedProducts] = useState({});
    const [uniqueCategoriesCount, setUniqueCategoriesCount] = useState(0);
    useEffect(() => {
        const fetchProducts = async () => {
            if (!brand?._id) return;
            try {
                const response = await axios.get(`https://node.instasales.in/api/v1/brand/products/${brand?._id}`);
                setProducts(response.data.products);

                setProductsLoading(false); // Set products loading to false after data is fetched
                extractCategoriesAndSubCategories(response.data.products);

                console.log("response", response);
            } catch (error) {
                console.error('Error fetching products:', error);
                setProductsLoading(false); // Set products loading to false in case of error
            }
        };

        fetchProducts();
    }, [brand?._id]);
    useEffect(() => {

        groupProductsByCategory(products)

    }, [products]);

    const extractCategoriesAndSubCategories = (products) => {
        const categoryMap = {};
        products.forEach(product => {
            const { category, sub_category } = product;
            if (category) {
                if (!categoryMap[category]) {
                    categoryMap[category] = new Set();
                }
                if (sub_category) {
                    categoryMap[category].add(sub_category);
                }
            }
        });
        setCategories(Object.keys(categoryMap));
        setSubCategories(Object.fromEntries(Object.entries(categoryMap).map(([k, v]) => [k, Array.from(v)])));
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setSelectedSubCategory(''); // Reset subcategory when category changes
    };

    const handleSubCategoryChange = (event) => {
        setSelectedSubCategory(event.target.value);
    };

    useEffect(() => {
        const fetchPopularProducts = async () => {
            if (!brand?._id) return;
            try {
                const response = await axios.get(`https://node.instasales.in/api/v1/brand/popular-products/${brand?._id}`);
                setPopularProducts(response.data.popularProducts);
                setPopularLoading(false); // Set popular loading to false after data is fetched
            } catch (error) {
                console.error('Error fetching popular products:', error);
                setPopularLoading(false); // Set popular loading to false in case of error
            }
        };

        fetchPopularProducts();
    }, [brand?._id]);



    const getUniqueCategories = (products) => {
        const uniqueCategories = new Set();
        products.forEach(product => {
            uniqueCategories.add(product.category);

        });
        return Array.from(uniqueCategories);
    };



    const [sortedby, setSortedby] = useState()

    console.log("srorted by", sortedby)

    const groupProductsByCategory = (products) => {
        const uniqueCategories = getUniqueCategories(products);
        console.log("uniue category", uniqueCategories)
        setUniqueCategoriesCount(uniqueCategories.length);

        const grouped = products.reduce((acc, product) => {
            let Category;
            if (uniqueCategories.length === 1) {
                Category = product.sub_category || 'Others';
                setSortedby('sub_category')
            } else {
                Category = product.category || 'Others';
                setSortedby('category')

            }

            if (!acc[Category]) {
                acc[Category] = [];
            }
            acc[Category].push(product);
            return acc;
        }, {});

        setGroupedProducts(grouped);
    };
    const handleSubscribe = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post('https://node.instasales.in/api/v1/subscribe', {
                subscribeToId: brand?._id,
                type: 'Brand'
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data.success) {
                swal("Subscribed!", response.data.message, "success");
                checkSubscriptionStatus();
            } else {
                swal("Oops!", response.data.message, "error");
            }
        } catch (error) {
            console.error('Error subscribing:', error);
            swal("Oops!", "Something went wrong!", "error");
        }
    };

    const handleUnsubscribe = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.delete('https://node.instasales.in/api/v1/unsubscribe', {
                data: {
                    subscribeToId: brand?._id,
                    type: 'Brand'
                },
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data.success) {
                setIsSubscribed(false);
                swal("Unsubscribed!", response.data.message, "success");
            } else {
                swal("Oops!", response.data.message, "error");
            }
        } catch (error) {
            console.error('Error unsubscribing:', error);
            swal("Oops!", "Something went wrong!", "error");
        }
    };

    const goToPrev = (subCategory) => {
        carouselRefs.current[subCategory].previous();
    };

    const goToNext = (subCategory) => {
        carouselRefs.current[subCategory].next();
    };

    if (brandLoading) {
        return (
            <div className="wishlist-loading">
                <ClipLoader size={50} color={"#123abc"} loading={brandLoading} />
            </div>
        );
    }


    const handleHoverDivClick = (link, subCategory) => {
        if (sortedby == 'category') {

            window.location.href = `/brand/${slug}/category/${subCategory}`;
        } else {

            setSelectedCubcategory(subCategory)
        }


    };
    const handleBrandFilterChange = (event) => {
        const brand = event.target.value;
        const isChecked = event.target.checked;
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            brands: isChecked ? [...prevFilters.brands, brand] : prevFilters.brands.filter(b => b !== brand)
        }));
    };

    const handlePriceRangeChange = (value) => {
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            priceRange: [0, value]
        }));
    };

    const handleDiscountRangeChange = (event) => {
        const value = event.target.value;
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            discountRange: [10, value]
        }));
    };


    const applyFilters = () => {
        let filtered = [...products];

        // Apply category and subcategory filter
        if (selectedCategory) {
            filtered = filtered.filter(product => product.category === selectedCategory);
        }
        if (selectedSubCategory) {
            filtered = filtered.filter(product => product.sub_category === selectedSubCategory);
        }

        // Apply price range filter
        const [minPrice, maxPrice] = selectedFilters.priceRange;
        filtered = filtered.filter(product => {
            const price = product.variations[0]?.options[0].selling_price;
            return price >= minPrice && price <= maxPrice;
        });

        // Filter by brands
        if (selectedFilters.brands.length > 0) {
            filtered = filtered.filter(product => selectedFilters.brands.includes(product.brand_name));
        }

        // Apply sorting
        if (selectedFilters.sort) {
            if (selectedFilters.sort === '1') { // Trending
                filtered = filtered.sort((a, b) => b.popularity - a.popularity); // Assuming popularity is a field
            } else if (selectedFilters.sort === '2') { // Price: High to Low
                filtered = filtered.sort((a, b) => b.variations[0]?.options[0].selling_price - a.variations[0]?.options[0].selling_price);
            } else if (selectedFilters.sort === '3') { // Price: Low to High
                filtered = filtered.sort((a, b) => a.variations[0]?.options[0].selling_price - b.variations[0]?.options[0].selling_price);
            }
        }

        setProducts(filtered);
        handleClose();
    };



    return (
        <>
            <Helmet>
                <title>{`${brand?.brandName} - Explore the Best Products`}</title>
                <meta name="description" content={brand?.brand_desc} />
                <meta name="keywords" content={`${brand?.brandName}, ${brand?.category}, ${brand?.subCategory}`} />
                <meta name="author" content={brand?.brandName} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content={`${brand?.brandName} - Explore the Best Products`} />
                <meta property="og:description" content={brand?.brand_desc} />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="creator">
                <div className="creator-header">
                    <Button className="creator-btn1" onClick={handleShow}><FontAwesomeIcon icon={faFilter} /> Filters</Button>
                </div>
                <div className="creator-social">
                    <Button
                        className="creator-btn2"
                        onClick={isSubscribed ? handleUnsubscribe : handleSubscribe}
                    >
                        <FontAwesomeIcon icon={isSubscribed ? faBellSlash : faBell} /> {isSubscribed ? 'Subscribed' : 'Subscribe'}
                    </Button>
                    <a className="creator-rd-start"><FontAwesomeIcon icon={faInstagram} size='2x' className="creator-social-rd" /></a>
                    <a><FontAwesomeIcon icon={faTwitter} size='2x' className="creator-social-rd" /></a>
                    <a><FontAwesomeIcon icon={faFacebook} size='2x' className="creator-social-rd" /></a>
                </div>
                <Card className="text-white creator-card">
                    <Card.Img src={brand?.brandLogo?.url ? brand?.brandLogo?.url : cardbg} alt="Card image" className="creator-card-img" style={{ marginBottom: "0" }} />
                    {/* <Card.ImgOverlay className="creator-card-txt">
                </Card.ImgOverlay> */}
                    <Card.ImgOverlay className="brands-all-content-gradient2" style={{ top: "auto", bottom: "0" }}>
                        <Card.Title className="creator-card-h">{brand.brandName}</Card.Title>

                    </Card.ImgOverlay>
                </Card>
                {popularProducts.length > 0 && (
                    <>

                        {popularLoading ? (
                            <div className="wishlist-loading">
                                <ClipLoader size={50} color={"#123abc"} loading={popularLoading} />
                            </div>
                        ) : (
                            <Row className={`creator-products-row bg-blue}`}>
                                <Col lg={4} className="creator-products-content">
                                    <div className="creator-products-h">Popular Products</div>
                                    <div className="creator-products-p"></div>
                                    <div className="creator-products-ctrl">
                                        <Button className="custom-left-arrow" onClick={goToPrevPopular}>
                                            <FontAwesomeIcon icon={faCaretLeft} size='2x' />
                                        </Button>
                                        <Button className="custom-right-arrow" onClick={goToNextPopular}>
                                            <FontAwesomeIcon icon={faCaretRight} size='2x' />
                                        </Button>
                                    </div>
                                    {/* <Button className="creator-products-btn" onClick={() => handleHoverDivClick(`/brand/${id}/category/${subCategory}`, subCategory)}>View All</Button> */}
                                </Col>
                                <Col lg={8} className="creator-products-carousel-col">
                                    <Carousel
                                        responsive={responsive}
                                        arrows={false}
                                        ref={popularCarouselRef}
                                        className="creator-products-carousel"
                                    >
                                        {popularProducts.map((product, idx) => (
                                            <Link to={`/product/${product.brand_slug}/${product._id}`}>
                                                <Card key={idx} className="creator-products-card">
                                                    <img src={product.images[0]?.url || cardbg} alt="card-img" className="creator-products-card-img" />
                                                    <div className="creator-products-price">₹{product?.variations[0]?.options[0]?.selling_price}</div>
                                                    <div className="creator-products-title">{product.name}</div>
                                                    <div className="creator-products-desc">{product.description}</div>
                                                </Card>
                                            </Link>
                                        ))}
                                    </Carousel>
                                </Col>
                            </Row>
                        )}

                    </>
                )}

                {productsLoading ? (
                    <div className="wishlist-loading">
                        <ClipLoader size={50} color={"#123abc"} loading={brandLoading} />
                    </div>
                ) : (
                    <>
                        {selectedCubcategory != null ? <BrandSeectedCategory brand={brand} subCategory={selectedCubcategory} setSelectedCubcategory={setSelectedCubcategory} /> : (
                            <>


                                {Object.keys(groupedProducts).map((subCategory, index) => (
                                    <Row key={index} className={`creator-products-row bg-${index % 2 === 0 ? 'green' : index % 3 === 0 ? 'blue' : 'blue'}`}>
                                        <Col lg={4} className="creator-products-content">
                                            <div className="creator-products-h">{subCategory}</div>
                                            <div className="creator-products-p"></div>
                                            <div className="creator-products-ctrl">
                                                <Button className="custom-left-arrow" onClick={() => goToPrev(subCategory)}>
                                                    <FontAwesomeIcon icon={faCaretLeft} size='2x' />
                                                </Button>
                                                <Button className="custom-right-arrow" onClick={() => goToNext(subCategory)}>
                                                    <FontAwesomeIcon icon={faCaretRight} size='2x' />
                                                </Button>
                                            </div>
                                            <Button className="creator-products-btn" onClick={() => handleHoverDivClick(`/brand/${slug}/category/${subCategory}`, subCategory)}>View All</Button>
                                        </Col>
                                        <Col lg={8} className="creator-products-carousel-col">
                                            <Carousel
                                                responsive={responsive}
                                                arrows={false}
                                                ref={(el) => (carouselRefs.current[subCategory] = el)}
                                                className="creator-products-carousel"
                                            >
                                                {groupedProducts[subCategory].map((product, idx) => (
                                                    <Link to={`/product/${product.brand_slug}/${product._id}`} key={product._id}>
                                                        <Card key={idx} className="creator-products-card">
                                                            <img src={product.images[0]?.url || cardbg} alt="card-img" className="creator-products-card-img" />
                                                            <div className="creator-products-price">₹{product?.variations[0]?.options[0]?.selling_price}</div>
                                                            <div className="creator-products-title">{product.name}</div>
                                                            <div className="creator-products-desc">{product.description}</div>
                                                        </Card>
                                                    </Link>
                                                ))}
                                            </Carousel>
                                        </Col>
                                    </Row>
                                ))}

                            </>
                        )}
                    </>
                )}

                <Modal show={show1} onHide={handleClose1} backdrop="static" keyboard={false} size="lg" className="creator-modal1">
                    <Modal.Header className="creator-modal1-header" closeButton>
                        Say Something...
                    </Modal.Header>
                    <Modal.Body className="creator-modal1-body">
                        <Form.Control as="textarea" rows={5} placeholder="Write a message..." className="creator-modal1-fc" />
                        <Button className="creator-modal1-btn" onClick={alert1}>Sign-up</Button>
                    </Modal.Body>
                </Modal>

                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg" className="creator-modal">
                    <Modal.Header className="creator-modal-header" closeButton>
                        <Modal.Title className="creator-modal-title"><FontAwesomeIcon icon={faFilter} onClick={handleShow} /> Filters</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="creator-modal-row">
                            <Col className="creator-modal-col">
                                <Form.Select className="creator-modal-select" value={selectedCategory} onChange={handleCategoryChange}>
                                    <option value="">Select Category</option>
                                    {categories.map((category) => (
                                        <option key={category} value={category}>{category}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col className="creator-modal-col">
                                <Form.Select className="creator-modal-select" value={selectedSubCategory} onChange={handleSubCategoryChange} disabled={!selectedCategory}>
                                    <option value="">Select Subcategory</option>
                                    {(subCategories[selectedCategory] || []).map((subCategory) => (
                                        <option key={subCategory} value={subCategory}>{subCategory}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="creator-modal-row">
                            <Col className="creator-modal-col">
                                <Form.Select className="creator-modal-select" value={selectedFilters.sort} onChange={(e) => setSelectedFilters({ ...selectedFilters, sort: e.target.value })}>
                                    <option value="">Sort</option>
                                    <option value="1">Trending (Most Popular)</option>
                                    <option value="2">Price: High to Low</option>
                                    <option value="3">Price: Low to High</option>
                                </Form.Select>
                            </Col>
                            <Col className="creator-modal-col">
                                <Form.Select className="creator-modal-select" value={selectedFilters.discount} onChange={(e) => setSelectedFilters({ ...selectedFilters, discount: e.target.value })}>
                                    <option value="">Discount</option>
                                    <option value="1">Current Sale</option>
                                    <option value="2">Over 50% off</option>
                                    <option value="3">Over 20% off</option>
                                </Form.Select>
                            </Col>
                        </Row>

                        <Row className="creator-modal-row">
                            <Col className="creator-modal-col-range">
                                <Form.Label>Price (₹)</Form.Label>
                                <Slider
                                    range={true}
                                    step={10}
                                    defaultValue={[10, maxPrice]}
                                    min={0}
                                    max={maxPrice}
                                    onChange={changeEvent => handlePriceRangeChange(changeEvent.target.value)}
                                    railStyle={{ backgroundColor: "#959595" }}
                                    trackStyle={{ backgroundColor: "#959595" }}
                                />
                                <span className="creator-modal-range-start">₹{selectedFilters.priceRange[0]}</span>
                                <span className="creator-modal-range-end">₹{selectedFilters.priceRange[1]}</span>
                            </Col>
                            <Col className="creator-modal-col-range">
                                <Form.Label>Discount</Form.Label>
                                <RangeSlider
                                    value={selectedFilters.discountRange[1]}
                                    onChange={changeEvent => handleDiscountRangeChange(changeEvent)}
                                    min={10}
                                    max={95}
                                    tooltip="on"
                                    tooltipPlacement="top"
                                />
                                <span className="creator-modal-range-start">{selectedFilters.discountRange[0]}%</span>
                                <span className="creator-modal-range-end">{selectedFilters.discountRange[1]}%</span>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="creator-modal-footer">
                        <Button className="creator-modal-btn" onClick={applyFilters}>
                            Apply
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>

    )

}


export default Brand;
