import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Button, Card, Col, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import cardbg from "../../media/card.png";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { ClipLoader } from 'react-spinners';
import "./searchresult.css";
import notFoundImg from '../../media/notFoundImg.png';

const SearchResults = () => {
  const location = useLocation();
  const [results, setResults] = useState({ products: [], creators: [], brands: [], subcategoryProducts: [], subSubcategoryProducts: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const carouselRef = useRef(null);
  const carousel1Ref = useRef(null);

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 4 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
  };

  const goToPrev = () => {
    carouselRef.current.previous();
  };

  const goToNext = () => {
    carouselRef.current.next();
  };

  const responsive2 = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const goToPrev1 = () => {
    carousel1Ref.current.previous();
};
const goToNext1 = () => {
    carousel1Ref.current.next();
};

  const query = new URLSearchParams(location.search).get('q');

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const { data } = await axios.get(`https://node.instasales.in/api/v1/result?keyword=${query}`);
        setResults({
          products: data.products,
          creators: data.creators,
          brands: data.brands,
          subcategoryProducts: data.subcategoryProducts,
          subSubcategoryProducts: data.subSubcategoryProducts,
        });
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch search results.');
        setLoading(false);
        console.error("Error fetching search results:", err);
      }
    };
    fetchResults();
  }, [query]);
console.log("result",results)

  const [products, setProducts] = useState([]);

  useEffect(() => {
      const fetchProducts = async () => {
          try {
              const { data } = await axios.get('https://node.instasales.in/api/v1/products');
              setProducts(data.product);
          } catch (error) {
              console.error('Error fetching products:', error);
          }
      };

      fetchProducts();
  }, []);

  if (loading) {
    return (
      <div className="wishlist-loading">
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  }
  if (error) return <div>{error}</div>;

  const noResults = results.products.length === 0 && results.creators.length === 0 && results.brands.length === 0 && results.subcategoryProducts.length === 0 && results.subSubcategoryProducts.length === 0;


  return (
    <div className='home'>
      <Row style={{ borderBottom: "1px solid #3F3F3F" }}>
        <Col className="brands-h" lg={11}>Search Results for "{query}" {noResults && <>(No Results Found)</>}</Col>
        <Col className="brands-color" lg={1}></Col>
      </Row>

      {noResults ? (
        <>
        <div className="no-results">
          <img src={notFoundImg} alt="Not Found" className="not-found-img" />
        </div>

        <div className="home-products">
                <Row className="home-products-row">
                    <Col lg={4} className="home-products-content">
                        <div className="home-products-h">Latest Products.</div>
                        <div className="home-products-p">Be The First to Grab</div>
                        <div className="home-products-ctrl">
                            <Button className="custom-left-arrow" onClick={goToPrev1}>
                                <FontAwesomeIcon icon={faCaretLeft} size='2x' />
                            </Button>
                            <Button className="custom-right-arrow" onClick={goToNext1}>
                                <FontAwesomeIcon icon={faCaretRight} size='2x' />
                            </Button>
                        </div>
                        <Button className="home-products-btn">View All</Button>
                    </Col>
                    <Col lg={8} className="home-products-carousel-col">
                        <Carousel responsive={responsive2} arrows={false} ref={carousel1Ref} className="home-products-carousel">
                            {products.map((product) => (
                                <Link to={`/product/${product.brand_slug}/${product._id}`} >
                                    <Card className="home-products-card">
                                        <img src={product.images[0].url} alt="card-img" className="home-products-card-img product-card-img" />
                                        <div className="home-products-price">₹{product?.variations[0]?.options[0]?.selling_price}</div>
                                        <div className="home-products-title">{product?.name}</div>
                                        <div className="home-products-desc">{product?.description}</div>
                                    </Card>
                                </Link>
                            ))}
                        </Carousel>
                    </Col>
                </Row>
            </div>

        </>
      ) : (
        <>
       
      <Row>
        <Col>
          {results.products.length > 0 ? (
            <div className="home-creators">
              <Row className="home-creators-head">
                <Col lg={10} className="home-creators-txt">
                  <div className="home-creators-h">Products</div>
                </Col>
                <Col lg={2} className="home-creators-ctrl">
                  <Button className="custom-left-arrow" onClick={goToPrev}>
                    <FontAwesomeIcon icon={faCaretLeft} size='2x' />
                  </Button>
                  <Button className="custom-right-arrow" onClick={goToNext}>
                    <FontAwesomeIcon icon={faCaretRight} size='2x' />
                  </Button>
                </Col>
              </Row>

              <div className="home-creators-card-wrapper">
                <div className="home-creators-card-bg search-product-bg"></div>
                <Carousel responsive={responsive} className="home-creators-cards" ref={carouselRef} showDots={false} arrows={false}>
                  {results.products.map((product) => (
                    <Link key={product._id} to={`/product/${product.brand_slug}/${product._id}`}>
                      <Card className=" categories-products-card card">
                        <img src={product.images[0].url} alt="card-img" className="home-products-card-img product-card-img categories-products-card-img" />
                        <div className="home-products-price">₹{product?.variations[0]?.options[0]?.selling_price}</div>
                        <div className="home-products-title">{product?.name}</div>
                        <div className="home-products-desc">{product?.description}</div>
                      </Card>
                    </Link>
                  ))}
                </Carousel>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          {results.creators.length > 0 ? (
            <div className="home-creators">
              <Row className="home-creators-head">
                <Col lg={10} className="home-creators-txt">
                  <div className="home-creators-h">Creators</div>
                </Col>
                <Col lg={2} className="home-creators-ctrl">
                  <Button className="custom-left-arrow" onClick={goToPrev}>
                    <FontAwesomeIcon icon={faCaretLeft} size='2x' />
                  </Button>
                  <Button className="custom-right-arrow" onClick={goToNext}>
                    <FontAwesomeIcon icon={faCaretRight} size='2x' />
                  </Button>
                </Col>
              </Row>

              <div className="home-creators-card-wrapper">
                <div className="home-creators-card-bg search-creator-bg"></div>
                <Carousel responsive={responsive} className="home-creators-cards" ref={carouselRef} showDots={false} arrows={false}>
                  {results.creators.map((creator) => (
                    <Link key={creator._id} to={`/creator/${creator.slug}`}>
                      <Card className="text-white home-creators-card">
                        <Card.Img src={creator?.avatar?.url ? creator?.avatar?.url : cardbg} alt="Card image" className="home-creators-card-img" />
                        <Card.ImgOverlay className="home-creators-card-txt">
                          <Card.Title className="home-creators-card-h">{creator?.creatorName}</Card.Title>
                          <Card.Text className="home-creators-card-p">{creator?.creatorType}</Card.Text>
                        </Card.ImgOverlay>
                        <span className="home-creators-card-stats">{creator.followers}</span>
                      </Card>
                    </Link>
                  ))}
                </Carousel>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          {results.brands.length > 0 ? (
            <div className="home-creators">
              <Row className="home-creators-head">
                <Col lg={10} className="home-creators-txt">
                  <div className="home-creators-h">Brands</div>
                </Col>
                <Col lg={2} className="home-creators-ctrl">
                  <Button className="custom-left-arrow" onClick={goToPrev}>
                    <FontAwesomeIcon icon={faCaretLeft} size='2x' />
                  </Button>
                  <Button className="custom-right-arrow" onClick={goToNext}>
                    <FontAwesomeIcon icon={faCaretRight} size='2x' />
                  </Button>
                </Col>
              </Row>

              <div className="home-creators-card-wrapper">
                <div className="home-creators-card-bg search-brand-bg"></div>
                <Carousel responsive={responsive} className="home-creators-cards" ref={carouselRef} showDots={false} arrows={false}>
                  {results.brands.map((brand) => (
                    <Link key={brand._id} to={`/brand/${brand.slug}`}>
                      <Card className="text-white home-creators-card">
                        <Card.Img src={brand?.brandLogo?.url ? brand?.brandLogo?.url : cardbg} alt="Card image" className="home-creators-card-img" />
                        <Card.ImgOverlay className="home-creators-card-txt">
                          <Card.Title className="home-creators-card-h">{brand.brandName}</Card.Title>
                        </Card.ImgOverlay>
                      </Card>
                    </Link>
                  ))}
                </Carousel>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      </>
      )}

    </div>
  );
};

export default SearchResults;
