import React, { useContext, useEffect, useState } from 'react';
import './sumarry.css';
import { Row, Col, Card, Button, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPenToSquare, faRotate } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useProductContext } from '../ProductContext';
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom';
// import LoaderModal from './OriginalProcessmodel';
import OrderProcessingModal from './OriginalProcessmodel';
import swal from 'sweetalert';

const ProductSummary = () => {
    const { selectedProducts, addProduct, removeProduct, clearProducts } = useProductContext();
    const [addresses, setAddresses] = useState([]);
    const { user, setUser } = useContext(UserContext);
    const [showLoader, setShowLoader] = useState(false); // State to control the loader modal
    const [showEditForm, setShowEditForm] = useState(false);
    const [showChangeForm, setShowChangeForm] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchAddresses();
    }, []);

    const fetchAddresses = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get('https://node.instasales.in/api/v1/address', {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            setAddresses(response.data.addresses);
            setSelectedAddress(response.data.addresses[0]?._id || null);
        } catch (error) {
            console.error('Error fetching addresses:', error);
        }
    };


    const handleIncrement = (productId, currentQuantity) => {
        const newQuantity = currentQuantity + 1;
        addProduct(productId, newQuantity);
    };

    const handleDecrement = (productId, currentQuantity) => {
        if (currentQuantity > 1) {
            const newQuantity = currentQuantity - 1;
            addProduct(productId, newQuantity);
        }
    };

    const totalPrice = selectedProducts.reduce((acc, product) => acc + (parseInt(product.selectedVariation?.selling_price) * product.quantity), 0);

    const handlePlaceOrder = async () => {
        const token = localStorage.getItem('token');

        setShowLoader(true); // Show the loader modal

        const orderItems = selectedProducts.map(product => ({
            name: product.name,
            price: product.selectedVariation?.selling_price,
            quantity: product.quantity,
            image: product?.images[0]?.url,
            product: product._id,
            variation: product.selectedVariation,
            brand_id: product.brand_id
        }));

        const shippingInfo = {
            address: addresses[0]?.houseNo + ', ' + addresses[0]?.street + ', ' + addresses[0]?.landmark,
            city: addresses[0]?.city,
            state: addresses[0]?.state,
            country: 'India',
            pinCode: addresses[0]?.postalCode,
            phoneNo: addresses[0]?.phoneNumber,
        };

        const orderData = {
            shippingInfo,
            orderItems,
            itemsPrice: totalPrice,
            taxPrice: 0,
            shippingPrice: 40,
            totalPrice: totalPrice + 40,
            user: user._id, // Assuming you have user context to get the logged-in user's ID
        };

        try {
            const response = await axios.post('https://node.instasales.in/api/v1/order/new', orderData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            console.log("response", response)
            if (response.data.success) {
                setShowLoader(false); // Hide the loader modal
                console.log("object", response.data.orders)
                navigate(`/order-confirmation/${response.data.orders[0]._id}`);
                clearProducts();
            }
        } catch (error) {
            setShowLoader(false);

            console.error('Error placing order:', error);
        }
    };

    const [addressForm, setAddressForm] = useState({
        name: selectedAddress?.name,
        houseNo: selectedAddress?.houseNo,
        street: selectedAddress?.street,
        landmark: selectedAddress?.landmark,
        city: selectedAddress?.city,
        state: selectedAddress?.state,
        postalCode: selectedAddress?.postalCode
    });



    const handleEditAddress = (address) => {
        console.log("address in edit", address)
        setEditAddressId(address);
        const addresseditto = addresses.find(addr => addr._id === address)
        setFormData(addresseditto);

        setShowEditForm(true);
        setShowChangeForm(false);
    };

    const handleChangeAddressClick = () => {
        setShowChangeForm(true);
        setShowEditForm(false);
    };

    const handleAddressFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        const { name, value } = e.target;
        setFormData({ ...formData, [e.target.name]: e.target.value });

        // If the field is postalCode and the length is 6, fetch the address details
        if (name === 'postalCode' && value.length === 6) {
            fetchAddressDetails(value);
        }

    };

    const handleAddressFormSubmit = async (e) => {
        const token = localStorage.getItem('token');

        e.preventDefault();
        try {
            await axios.post('https://node.instasales.in/api/v1/address', addressForm, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            fetchAddresses();
            setShowEditForm(false);
            setShowChangeForm(false);
        } catch (error) {
            console.error('Error updating address:', error);
        }
    };

    const handleAddressSelection = (e) => {
        console.log("object", e.target.value)
        setSelectedAddress(e.target.value);
    };

    const handleChangeAddress = async (e) => {
        e.preventDefault();
        // fetchAddresses();
        setShowEditForm(false);
        setShowChangeForm(false);
    }

    const handleAddressSelectionSubmit = async (e) => {
        const token = localStorage.getItem('token');

        e.preventDefault();
        try {
            await axios.put(`https://node.instasales.in/api/v1/address/${selectedAddress}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            fetchAddresses();
            setShowChangeForm(false);
        } catch (error) {
            console.error('Error selecting address:', error);
        }
    };

    const fetchAddressDetails = async (pincode) => {
        try {
            const response = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`);
            const data = response.data[0];
            console.log("data", data)
            if (data.Status === "Success" && data.PostOffice.length > 0) {
                const { District, block, State, Pincode } = data.PostOffice[0];
                setFormData({ ...formData, town: District || block, city: District || block, state: State, postalCode: Pincode });
            } else {
                console.error("Invalid pincode or no data found.");
            }
        } catch (error) {
            console.error('Error fetching address details:', error);
        }
    };

    const [editAddressId, setEditAddressId] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        houseNo: '',
        street: '',
        landmark: '',
        town: '',
        city: '',
        state: '',
        postalCode: ''
    });



    const handleUpdateAddress = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.put(`https://node.instasales.in/api/v1/address/${editAddressId}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (response.data.success) {
                fetchAddresses();
                setFormData({
                    name: '',
                    email: '',
                    phoneNumber: '',
                    houseNo: '',
                    street: '',
                    landmark: '',
                    town: '',
                    city: '',
                    state: '',
                    postalCode: ''
                });
                swal("Success!", "Address updated successfully!", "success");
                fetchAddresses();
                setShowEditForm(false);
                setShowChangeForm(false);
            }
        } catch (error) {
            console.error('Error updating address:', error);
            swal("Oops!", "Something went wrong while updating the address!", "error");
        }
    };


    return (
        <div className="cart">
            <OrderProcessingModal show={showLoader} onHide={() => setShowLoader(false)} />
            <p className="cart-h">Check your Order Summary</p>
            <Row className="cart-row">
                <Col lg={8} className="cart-col-1">
                    <Row className="cart-delivery">
                        {(!showEditForm && !showChangeForm) && (
                            <>
                                <Col lg={9} className="cart-address">
                                    <div>Deliver to: <span className="bold">{addresses.find(addr => addr._id === selectedAddress)?.name}</span></div>
                                    <div className="cart-address-text">{addresses.find(addr => addr._id === selectedAddress)?.houseNo}, {addresses.find(addr => addr._id === selectedAddress)?.street}, {addresses.find(addr => addr._id === selectedAddress)?.landmark}</div>
                                    <div className="cart-address-text">{addresses.find(addr => addr._id === selectedAddress)?.city}, {addresses.find(addr => addr._id === selectedAddress)?.state}, India {addresses.find(addr => addr._id === selectedAddress)?.postalCode}</div>
                                </Col>
                                <Col lg={3} className="cart-delivery-controls">
                                    <Button className="cart-edit" onClick={() => handleEditAddress(selectedAddress)}><FontAwesomeIcon icon={faPenToSquare} /> Edit</Button>
                                    <Button className="cart-change" onClick={handleChangeAddressClick}><FontAwesomeIcon icon={faRotate} /> Change</Button>
                                </Col>
                            </>
                        )}


                        {showEditForm && (
                            <>
                                <div className="edit-your">
                                    Edit Your Address
                                </div>
                                <Form onSubmit={handleAddressFormSubmit} className='form-changes'>
                                    <Row className="address-form-top">
                                        <Col className='edit-col'>
                                            <InputGroup as={Col} className="profile-input  profile-input2">
                                                <InputGroup.Text>Full Name</InputGroup.Text>
                                                <Form.Control type="text" name="name" value={formData.name} onChange={handleAddressFormChange} />
                                            </InputGroup>
                                            <InputGroup as={Col} className="profile-input profile-input2">
                                                <InputGroup.Text>Email</InputGroup.Text>
                                                <Form.Control type="mail" name="email" value={formData.email} onChange={handleAddressFormChange} />
                                            </InputGroup>
                                            <InputGroup className="profile-input profile-input2">
                                                <InputGroup.Text>+91</InputGroup.Text>
                                                <Form.Control type="number" name="phoneNumber" value={formData.phoneNumber} onChange={handleAddressFormChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className='edit-col'>
                                            <InputGroup className="profile-input profile-input2">
                                                <InputGroup.Text>Pincode</InputGroup.Text>
                                                <Form.Control type="text" name="postalCode" value={formData.postalCode} onChange={handleAddressFormChange} />
                                            </InputGroup>
                                            <InputGroup className="profile-input profile-input2">
                                                <InputGroup.Text>Town / City</InputGroup.Text>
                                                <Form.Control type="text" name="city" value={formData.city} onChange={handleAddressFormChange} />
                                            </InputGroup>
                                            <InputGroup className="profile-input profile-input2">
                                                <InputGroup.Text>State</InputGroup.Text>
                                                <Form.Control type="text" placeholder="State" name="state" value={formData.state} onChange={handleAddressFormChange} />
                                            </InputGroup>
                                        </Col>


                                    </Row>
                                    <Row className='form-bottom'>

                                        <InputGroup className="profile-input profile-input2 profile-input3 ">
                                            <InputGroup.Text>Flat, House no. , Apartment</InputGroup.Text>
                                            <Form.Control type="text" name="houseNo" value={formData.houseNo} onChange={handleAddressFormChange} />
                                        </InputGroup>
                                        <InputGroup className="profile-input profile-input2 profile-input3">
                                            <InputGroup.Text>Area, Street, Sector, Village</InputGroup.Text>
                                            <Form.Control type="text" name="street" value={formData.street} onChange={handleAddressFormChange} />
                                        </InputGroup>
                                        <InputGroup className="profile-input profile-input2 profile-input3">
                                            <InputGroup.Text>Landmark</InputGroup.Text>
                                            <Form.Control type="text" name="landmark" value={formData.landmark} onChange={handleAddressFormChange} />
                                        </InputGroup>
                                    </Row>
                                    <Button variant="primary" type="submit" className='save-address' onClick={handleUpdateAddress}>
                                        Save
                                    </Button>
                                </Form>
                            </>
                        )}

                        {showChangeForm && (
                            <Row className="address-form">
                                {/* <Form onSubmit={handleAddressSelectionSubmit}> */}
                                <Form >
                                    {addresses.map((address) => (
                                        <Form.Group key={address._id} controlId={`address-${address._id}`} className={`change-form ${addresses.length > 1 ? 'multiple-addresses' : ''}`}>
                                            <div className="deliver-to">
                                                <div>Deliver to: <span className="bold">{address.name}</span></div>
                                                <div className="cart-address-text">{address.houseNo}, {address.street}, {address?.landmark}</div>
                                                <div className="cart-address-text">{address?.city}, {address?.state}, India {address?.postalCode}</div>
                                            </div>
                                            <Form.Check
                                                type="radio"
                                                name="address"
                                                value={address._id}
                                                checked={selectedAddress === address._id}
                                                onChange={handleAddressSelection}
                                            />
                                        </Form.Group>
                                    ))}

                                    <Button variant="primary" type="submit" className='save-address' onClick={handleChangeAddress}>
                                        Save
                                    </Button>
                                </Form>
                            </Row>
                        )}
                    </Row>
                    <Row className="cart-items">
                        {selectedProducts.map((item, index) => (
                            <Card className="cart-item" key={index}>
                                <Row className="cart-item-details">
                                    <Col lg={3} className="cart-item-img-container">
                                        <img src={item?.images[0]?.url} className="cart-item-img" alt={item.name} />
                                    </Col>
                                    <Col lg={7} className="cart-item-text">
                                        <div className="cart-item-text-h">{item?.name}</div>
                                        <div className="cartitem-varient">Variant : {item?.selectedVariation?.name} </div>
                                        <div className="cart-item-text-promo">APPLIED PROMOCODE : 50% off on the {item?.category} product</div>
                                        <Button className="cart-item-btn-promo">Change promocode</Button>
                                        <p className="cart-item-price">₹{item.selectedVariation?.selling_price}</p>
                                    </Col>
                                    <Col className='item-amount' style={{ marginTop: "20px" }}>
                                        <Button className="cart-item-sub" style={{ marginLeft: "0" }} onClick={() => handleDecrement(item, item.quantity)}>-</Button>
                                        <span className="cart-item-count">{item.quantity}</span>
                                        <Button className="cart-item-add" onClick={() => handleIncrement(item, item.quantity)}>+</Button>
                                    </Col>
                                </Row>
                            </Card>
                        ))}
                    </Row>
                </Col>
                <Col lg={4} className="cart-col-2">
                    <p className="cart-delivery-text">Estimated Delivery : <span className="cart-delivery-date">Fri Oct 21</span></p>
                    <Form.Label className="cart-promo-text bold">APPLY PROMOCODE</Form.Label>
                    <InputGroup>
                        <Form.Control className="cart-promo-box" placeholder="Add promo code" />
                        <Button className="cart-promo-btn"><FontAwesomeIcon icon={faPaperPlane} /></Button>
                    </InputGroup>
                    <div className="cart-price">
                        <div className="cart-price-text bold">Price Details</div>
                        <div><span className="cart-price-left">Price ({selectedProducts?.length} item{selectedProducts?.length > 1 ? 's' : ''})</span><span className="cart-price-right">₹{totalPrice}</span></div>
                        <div><span className="cart-price-left">Delivery Fee</span><span className="cart-price-right">₹40</span></div>
                        <hr className="custom-hr-1"></hr>
                        <div><span className="cart-price-left bold">Total Amount</span><span className="cart-price-right bold">₹{totalPrice + 40}</span></div>
                        <Button className="cart-btn" onClick={handlePlaceOrder}>Place Order</Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ProductSummary;
