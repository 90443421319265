import React, { useEffect, useState } from 'react';
import './sumarry.css'; // Ensure your CSS file has the appropriate styles
import { Row, Col, Card, Button } from 'react-bootstrap';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import card from "../../media/card.svg"
import truck from "../../media/truck.svg"
import cc from "../../media/cc.svg"
import hand from "../../media/hand.png"
import ClipLoader from 'react-spinners/ClipLoader'; // Import the spinner


const OrderConfirmation = () => {
    const { orderId } = useParams();
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        fetchOrderDetails();
    }, []);

    const fetchOrderDetails = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(`https://node.instasales.in/api/v1/order/${orderId}`, {
                            headers: {
              'Authorization': `Bearer ${token}`
            },
            });
            setOrder(response.data.order);
        } catch (error) {
            console.error('Error fetching order details:', error);
        } finally {
            setLoading(false); // Set loading to false after fetching
        }
    };

    if (!order) {
        return <div className="wishlist-loading">
            <ClipLoader size={50} color={"#123abc"} loading={loading} />
        </div>
    }

    if (loading) {
        return (
            <div className="wishlist-loading">
                <ClipLoader size={50} color={"#123abc"} loading={loading} />
            </div>
        );
    }
    console.log("oder detail", order)
    return (
        <div className="order-confirmation">
            <div className="confirmation-header">
                <h2> <img src={hand} alt="" />Congrats! Your Order was Placed</h2>
            </div>
            {order.orderItems.map((ord) => (

                <Card className="confirmation-card">
                    <Row className="order-item-details">
                        <Col lg={3} className="order-item-img-container">
                            <img src={ord.image} className="order-item-img" alt={ord.name} />
                        </Col>
                        <Col lg={6} className="order-item-text">
                            <h4>{ord.name}</h4>
                            <p>Order Id: {order._id}</p>
                            <p>Brand: {ord.product.brand_name}</p>
                            <p>Variant: {ord.variation?.name}</p>
                            <p className='price'>₹{ord.price}</p>
                        </Col>
                        <Col lg={3} className="order-item-text">
                        <Link to="/track-order"><Button className="track-order-btn">Track Order</Button></Link>

                        </Col>
                    </Row>
                </Card>
            ))}
            <div className="order-details">
                <h4>Order Details</h4>
                <Row className='oderdetailrow'>
                    <Col lg={4} className="order-detail-section">
                        <img src={cc} alt="" className='img-confirm'/>
                        <h5>Contact Information</h5>
                        <p>{order.user.email}</p>
                        <p>Ph no. {order.shippingInfo.phoneNo}</p>
                    </Col>
                    <Col lg={4} className="order-detail-section">
                        <img src={truck} alt="" className='img-confirm' />

                        <h5>Deliver to:</h5>
                        <p>{order.shippingInfo.address}</p>
                        <p>{order.shippingInfo.city}, {order.shippingInfo.state}, {order.shippingInfo.country} {order.shippingInfo.pinCode}</p>
                    </Col>
                    <Col lg={4} className="order-detail-section">
                        <img src={card} alt="" className='img-confirm' />

                        <h5>Payment Details</h5>
                        <p>Debit Card</p>
                        <p>₹{order.totalPrice}</p>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default OrderConfirmation;
