import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import './product.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faHeart, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../UserContext';
import { useProductContext } from '../ProductContext';
import ClipLoader from 'react-spinners/ClipLoader'; // Import the spinner

const CreatorProduct = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [activeImage, setActiveImage] = useState('');
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [cartFeedback, setCartFeedback] = useState('');
  const [isInCart, setIsInCart] = useState(false);
  const [cartQuantity, setCartQuantity] = useState(0);
  const [selectedVariation, setSelectedVariation] = useState(null); // State for selected variation
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { addProduct } = useProductContext();

  const fetchProductDetails = async () => {
    const token = localStorage.getItem('token');

    try {
      const { data } = await axios.get(`https://node.instasales.in/api/v1/product/${id}`);
      setProduct(data.product);
      setActiveImage(data.product.images[0]?.url || '');
      setSelectedVariation(data.product.variations[0]?.options[0]); // Set default variation

      const wishlistResponse = await axios.get('https://node.instasales.in/api/v1/wishlist', {
                    headers: {
              'Authorization': `Bearer ${token}`
            }
      });
      const wishlist = wishlistResponse.data.wishlist.products;
      setIsInWishlist(wishlist.some(p => p._id === data.product._id));

      const cartResponse = await axios.get('https://node.instasales.in/api/v1/cart', {
                    headers: {
              'Authorization': `Bearer ${token}`
            }
      });
      const cart = cartResponse.data.cart;
      const cartProduct = cart.products.find(p => p.product._id === data.product._id);
      if (cartProduct) {
        setIsInCart(true);
        setCartQuantity(cartProduct.quantity);
        setQuantity(cartProduct.quantity);
      }
    } catch (error) {
      console.error('Error fetching product details:', error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };
  console.log("object",product)

  useEffect(() => {
    fetchProductDetails();
  }, [id]);

  if (loading) {
    return (
      <div className="wishlist-loading">
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  }

  if (!product) {
    return <div>Loading...</div>;
  }

  const handleAddToWishlist = async (productId) => {
    const token = localStorage.getItem('token');

    try {
      await axios.put('https://node.instasales.in/api/v1/wishlist', { productId }, {
                    headers: {
              'Authorization': `Bearer ${token}`
            }
      });
      setIsInWishlist(true);
    } catch (error) {
      console.error('Error updating wishlist:', error);
    }
  };

  const handleRemoveFromWishlist = async (productId) => {
    const token = localStorage.getItem('token');

    try {
      await axios.put('https://node.instasales.in/api/v1/wishlist/remove', { productId }, {
                    headers: {
              'Authorization': `Bearer ${token}`
            }
      });
      setIsInWishlist(false);
    } catch (error) {
      console.error('Error removing from wishlist:', error);
    }
  };

  const handleAddToCart = async (productId, quantity) => {
    const token = localStorage.getItem('token');

    try {
      await axios.post('https://node.instasales.in/api/v1/cart', { productId, quantity , variant :selectedVariation }, {
                    headers: {
              'Authorization': `Bearer ${token}`
            }
      });
      setCartFeedback('Added to Cart');
      setTimeout(() => setCartFeedback(''), 3000);
      fetchProductDetails();
    } catch (error) {
      console.error('Error adding to cart:', error);
    }
  };

  const handleIncrement = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
    }
  };

  const handleBuyNow = async () => {
    const token = localStorage.getItem('token');

    addProduct({ ...product, selectedVariation }, quantity);
    if (!user) {
      navigate('/auth2');
    } else {
      try {
        const addressResponse = await axios.get('https://node.instasales.in/api/v1/address', {
                      headers: {
              'Authorization': `Bearer ${token}`
            }
        });
        if (!addressResponse.data.addresses) {
          navigate('/add-address');
        } else {
          navigate('/product-summary', { state: { product, selectedVariation, quantity } });
        }
      } catch (error) {
        console.error('Error checking address:', error);
      }
    }
  };

  const handleVariationChange = (variation) => {
    console.log("variation",variation)
    setSelectedVariation(variation);
  };

  const handleCategoryClick = (category) => {
    window.location.href = (`/categories/${category}`);
  };

  return (
    <div className="product">
      <div className="product-background-semicircle"></div>
      <Row className="product-details">
        <Col className="product-img" lg={4}>
          <img src={activeImage} alt="active-image" className="product-img-active" />
        </Col>
        <Col className="product-slider-thumbnails" lg={1}>
          {product?.images?.map((img, index) => (
            <img
              key={index}
              onClick={() => setActiveImage(img.url)}
              src={img.url}
              alt=""
              className={`product-slider-thumbnail pst ${activeImage === img.url ? 'active' : ''}`}
            />
          ))}
        </Col>
        <Col lg={7} className="product-info">
          <div className="product-h">{product?.name}</div>
          <div className="product-features inline">
            <Link to={`/brand/${product.brand_slug}`} className="product-feat"><span className="bold">Brand:</span>&nbsp;{product?.brand_name}</Link>
            <div onClick={() => handleCategoryClick(product.category)} className="product-feat"><span className="bold">Category:</span>&nbsp;{product.category}</div>
          </div>
          <div className="product-quantity inline">
            <div className="product-price">M.R.P.: <span className="product-p-original">₹{selectedVariation?.listing_price}</span></div>
            <div className="product-p-derived">₹{selectedVariation?.selling_price}</div>
            <Button className="product-btn-sub" onClick={handleDecrement}>-</Button>
            <span className="product-number">{quantity}</span>
            <Button className="product-btn-add" onClick={handleIncrement}>+</Button>
          </div>
          <div className="product-btns inline">
            {selectedVariation?.stock > 0 ?
              <>
                <Button className="product-buy" onClick={handleBuyNow}>
                  <FontAwesomeIcon icon={faCheck} size='xl' />&nbsp;Buy Now
                </Button>
                <Button className="product-cart" onClick={() => handleAddToCart(product._id, quantity)}>
                  <FontAwesomeIcon icon={faPlus} />&nbsp;{isInCart ? `In Cart (${cartQuantity})` : (cartFeedback || 'Add to Cart')}
                </Button>
              </>
              :
              <>
                <Button className="product-oos">
                  {selectedVariation?.stock > 0 ? "In Stock" : "Out of Stock"}
                </Button>
                <a href="/" className="product-notify">Notify me</a>
              </>
            }
          </div>
          <Button
            onClick={() => isInWishlist ? handleRemoveFromWishlist(product._id) : handleAddToWishlist(product._id)}
            className={`product-wishlist inline ${isInWishlist ? 'active' : ''}`}>
            <FontAwesomeIcon className={`heart ${isInWishlist ? 'active' : ''}`} icon={faHeart} />&nbsp;{isInWishlist ? 'Added to Wishlist' : 'Add to Wishlist'}
          </Button>
          <div><span className="bold">Variant: </span>{selectedVariation?.name}</div>
          <div className="product-net-quantities">
            {product?.variations[0]?.options?.map((variation, index) => (
              <Button key={index} className={`product-net-quantity ${selectedVariation?._id === variation._id ? 'selected' : ''}`} onClick={() => handleVariationChange(variation)}>
                {variation.name}
              </Button>
            ))}
          </div>
          <div className="product-description">
            <div className="product-desc-h">Description:</div>
            <div>{product?.description}</div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CreatorProduct;